const ButtonLink = props => {
  return (
    <button
      type='button'
      className={props.classes}
      onClick={props.onClickLink}
      style={{ margin: '1px' }}
    >
      {props.content}
    </button>
  );
};

export default ButtonLink;
