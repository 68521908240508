import { useHistory, Link } from "react-router-dom";

import HeroSection from "../containers/HeroSection";

import imgHeroLogo from "../pages/trophy-logo-1000x165px.png";
import imgHeroOverlay from "../pages/trophy-overlay-425x250.png";

const Home = () => {
  const history = useHistory();

  return (
    <div className="mb-5">
      <HeroSection sectionClassExtra="mb-5" logo={imgHeroLogo} overlay={imgHeroOverlay} />

      <div className="container-fluid home-intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5">
              <h3 className="text-white">
                The Iconic Hot-Hatch
              </h3>
              <div className="mt-3">
                The Renaultsport Clio 182 Trophy is a special car that is held in
                high-regard by the car community and automotive press alike. Despite
                launching back in 2005, this iconic hot-hatch continues to excite and
                offers driving thrills that its modern-day peers struggle to emulate.
                The Clio 182 Trophy is by no means exotic in nature, yet it remains
                rare and desirable to many performance car enthusiasts. You're
                really missing out if you haven't driven one...
              </div>

              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={() => history.push("/iconichothatch")}
              >
                Read More
              </button>
            </div>

            <div className="col-md-8 mb-5">
              <h3 className="text-white">What is TrophyTracker?</h3>
              <div className="mt-3">
                <strong>TrophyTracker</strong> is a non-commercial project that
                aims to provide owners and enthusiasts with useful information
                about these iconic Renaultsport hot-hatches. We aim to present
                this data through freely-available and/or community-provided
                information.
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-6 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => history.push("/search")}
                  >
                    <span>
                      <i className="fas fa-fw fa-search mr-1"></i> Start Search
                    </span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-6 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => history.push("/location")}
                  >
                    <span>
                      <i className="fas fa-fw fa-map-marker-alt mr-1"></i> Show Locations
                    </span>
                  </button>
                </div>
              </div>

              <div className="mt-3">
                Are you a Renaultsport Clio 182 Trophy owner or enthusiast? And
                can you perhaps help provide information about a specific vehicle?
                If so then please do consider contributing or getting in touch!
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-6 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => history.push("/submit")}
                  >
                    <span>
                      <i className="fas fa-fw fa-car mr-1"></i> Submit Info
                    </span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-6 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => history.push("/contact")}
                  >
                    <span>
                      <i className="fas fa-fw fa-envelope mr-1"></i> Contact Us
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <h3 className="text-white">Data Privacy &amp; Accuracy</h3>
              <div className="mt-3">
                The data held by TrophyTracker is deliberately sparse to avoid
                data protection issues and to maintain owner privacy. In addition,
                due to the way in which data is collected and maintained (through
                the searching of freely-available information or through community
                contributions) TrophyTracker cannot guarantee, or be held
                responsible for, its accuracy. You can find more information on
                our <Link to='/privacy'>Privacy Policy</Link> page.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
