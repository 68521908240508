import {
  SEARCH_ALLDATA,
  CLEAR_ALLDATA,
  SEARCH_TROPHY,
  CLEAR_TROPHY,
  SEARCH_METRICS,
  CLEAR_METRICS,
  SET_LOADING,
  SET_SERVERERROR,
  REMOVE_SERVERERROR,
} from '../types';

const trophysReducer = (state, action) => {
  switch (action.type) {
    case SEARCH_ALLDATA:
      return {
        ...state,
        allData: action.payload,
        loading: false,
      };

    case CLEAR_ALLDATA:
      return {
        ...state,
        allData: [],
        loading: false,
      };

    case SEARCH_TROPHY:
      return {
        ...state,
        trophy: action.payload,
        loading: false,
      };

    case CLEAR_TROPHY:
      return {
        ...state,
        trophy: null,
        loading: false,
      };

    case SEARCH_METRICS:
      //console.log(action.payload);
      return {
        ...state,
        metrics: action.payload,
        loading: false,
      };

    case CLEAR_METRICS:
      return {
        ...state,
        metrics: null,
        loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_SERVERERROR:
      return {
        ...state,
        serverError: action.payload,
      };

    case REMOVE_SERVERERROR:
      return {
        ...state,
        serverError: null,
      };

    default:
      return state;
  }
};

export default trophysReducer;