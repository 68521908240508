import Utility from '../util/Utility';

const TTTimelineContainer = ({ trophyInfo }) => {
  const timelineData = trophyInfo.timeline;

  const TimelineMileageAndValue = ({ eventItem }) => {
    const mileage = eventItem.eventMileage;
    const value = eventItem.eventValue;
    //console.log(mileage, value);

    if ((mileage > -1) && (value > -1)) {
      return (
        <div>
          <div><i className='fas fa-car fa-fw mr-2 text-muted' />{Utility.numWithCommas(mileage)} miles</div>
          <div><i className='fas fa-coins fa-fw mr-2 text-muted' />£{Utility.numWithCommas(value.toFixed(2))}</div>
        </div>
      );
    } else if (mileage > -1) {
      return (
        <span>
          <i className='fas fa-car fa-fw mr-2 text-muted' />
          {Utility.numWithCommas(mileage)} miles
        </span>
      );
    } else if (value > -1) {
      return (
        <span>
          <i className='fas fa-coins fa-fw mr-2 text-muted' />
          £{Utility.numWithCommas(value.toFixed(2))}
        </span>
      );
    }

    return null;
  };

  return (
    <>
      <h3 className='tt-heading text-warning'>Timeline</h3>
      <div className='mb-5'>
        {!timelineData && (
          <div className='text-white'>(No data available)</div>
        )}

        {timelineData && (
          <>
            <div className='text-white mb-2'>Please note that accuracy cannot be guaranteed and this is intended as a guide only.</div>

            <div className='mb-3'>
              <div className='text-muted mb-3'>
                ({Utility.getPluralisedYIESWithValueIfNeeded("entr", timelineData.length)})
              </div>

              {timelineData.map((eventItem, eventIdx) => {
                return (
                  <div className='card mt-4 position-relative' key={eventIdx}>
                    <div className='tt-timeline-icon-event-type center-gentle-flex'>
                      <i className={Utility.getIconForEventType(eventItem).icon} />
                    </div>
                    {eventIdx !== (timelineData.length - 1) && (
                      <div className='tt-timeline-icon-event-next center-gentle-flex'>
                        <i className='fas fa-angle-double-down' />
                      </div>
                    )}
                    <div className='card-body bg-dark'>
                      <div>
                        <strong>
                          <span className='text-warning'>
                            {new Date(eventItem.eventDate).toLocaleDateString('en-GB')}                          
                          </span>
                          <span className='text-muted mx-1'>-</span>
                          <span className='text-info'>
                            {eventItem.eventTypeDescription}
                          </span>
                        </strong>
                      </div>
                      {((eventItem.eventMileage > -1) || (eventItem.eventValue > -1)) && (
                        <div className='text-light mt-2'>
                          <TimelineMileageAndValue eventItem={eventItem} />
                        </div>
                      )}
                      {(eventItem.eventDescription !== null) && (
                        <div className='text-light mt-2'>
                          {eventItem.eventDescription}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}
 
export default TTTimelineContainer;