import { Fragment } from 'react';
import LazyLoad from 'react-lazyload';

const TTImagesContainer = ({ trophyInfo }) => {
  const GetImageURL = (trophyNumber, imgSrc) => { return `../../car-imgs/${trophyNumber.padStart(3, '0')}/${imgSrc}`; }
  const GetImageAlt = (trophyNumber) => { return `TrophyTracker - Trophy ${trophyNumber.padStart(3, '0')}`; }
  const GetImageTitle = (trophyNumber, count) => { return `Trophy ${trophyNumber.padStart(3, '0')} - Image ${count}`; }

  return (
    <>
      <h3 className='tt-heading text-warning'>Images</h3>
      <div className='tt-images-container'>
        <div className='text-white'>
          {trophyInfo.images ? (
            <Fragment>
              <div>(Images &copy; of their respective owners)</div>
              {trophyInfo.images.split(';').map((imgSrc, idx) => {
                return (
                  <div key={idx}>
                    <LazyLoad offset={100} once>
                      <img
                        className='tt-car-img'
                        src={GetImageURL(trophyInfo.number, imgSrc)}
                        alt={GetImageAlt(trophyInfo.number)}
                        title={GetImageTitle(trophyInfo.number, idx + 1)}
                      />
                    </LazyLoad>
                  </div>
                );
              })}
            </Fragment>
          ) : (
            <div>(No images available)</div>
          )}
        </div>
      </div>
    </>
  );
}
 
export default TTImagesContainer;
