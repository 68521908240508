const HeroSection = ({ sectionClassExtra, logo, overlay }) => {
  const classContent = "hero-section" + (((sectionClassExtra != null) && (sectionClassExtra.length > 0)) ? " " + (sectionClassExtra) : (""));
  
  return (
    <section className={classContent}>
      <div className="container">
        <div className="hero-content d-flex justify-content-start align-items-center">
          <img className="hero-logo" src={logo} alt="" />
          <img className="hero-image-overlay" src={overlay} alt="" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
