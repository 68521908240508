import ChangeLog from "../../change-log";
import BackToTop from "../layout/BackToTop";
import MiniHeroSection from "../containers/MiniHeroSection";

const ChangeList = () => {
  return (
    <div className="mb-5">
      <MiniHeroSection titleText="Change List" sectionClassExtra="mb-3" iconName="fa-list-alt" />

      <div className="container">
        <p>
          This page lists the significant changes that have been made to{" "}
          <strong>TrophyTracker</strong> over time.
        </p>

        {ChangeLog.map((change) => (
          <span key={change.version}>
            <h5>
              {change.version}{" "}
              {change.commit ? (
                <a
                  rel="noopener noreferrer"
                  href={
                    "https://github.com/andyeder/trophy-tracker/commit/" +
                    change.commit
                  }
                  target="GitHub"
                >
                  GitHub Commit
                </a>
              ) : (
                ""
              )}
            </h5>
            {change.log}
          </span>
        ))}
        <BackToTop />
      </div>
    </div>
  );
};

export default ChangeList;
