import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const NavBar = ({ appIcon, appTitle }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary mb-0">
      <div className="container">
        <Link className="navbar-brand" to="/">
          {appTitle}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="navbar-nav">
            <Link className="nav-item nav-link" to="/search">
              Search
            </Link>
            <Link className="nav-item nav-link" to="/location">
              Location
            </Link>
            <Link className="nav-item nav-link" to="/submit">
              Submit
            </Link>
            <Link className="nav-item nav-link" to="/contact">
              Contact
            </Link>
            <Link className="nav-item nav-link" to="/stats">
              Stats
            </Link>
            <Link className="nav-item nav-link" to="/motcheck">
              MOT Check
            </Link>
            <Link className="nav-item nav-link" to="/welshweekender">
              TWW
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

// These default props are used if none are specified in App.js - can be overidden
NavBar.defaultProps = {
  appIcon: "fab fa-info",
  appTitle: "TrophyTracker",
};

// Proptypes are effectively for type-checking
NavBar.propTypes = {
  appIcon: PropTypes.string.isRequired,
  appTitle: PropTypes.string.isRequired,
};

export default NavBar;
