import { useState } from 'react';

import Alert from '../layout/Alert';
import Spinner from '../layout/Spinner';
import BackToTop from '../layout/BackToTop';
import MiniHeroSection from "../containers/MiniHeroSection";
import TTChartsMOTMileage from '../containers/TTChartsMOTMileage';

import axios from 'axios';
import Utility from '../util/Utility';

const MOTCheck = () => {
  const [textReg, setTextReg] = useState('');

  const [motData, setMotData] = useState(null);
  const [motDataIsPending, setMotDataIsPending] = useState(false);
  const [motDataError, setMotDataError] = useState(null);

  const onClickRequestMOTData = async (evt) => {
    evt.preventDefault();

    setMotData(null);
    setMotDataError(null);

    // Trim and remove whitespace from supplied registration, convert to uppercase
    let valStr = textReg.trim();
    valStr = valStr.replace(/\s/g, '').toUpperCase();

    // Check for a valid registration - https://gist.github.com/danielrbradley/7567269
    const regExp = new RegExp(
      '(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)'
    );
    if (!regExp.test(valStr)) {
      // Invalid registration - early out
      setMotDataError('Please enter a valid registration!');
      return;
    }

    setMotDataIsPending(true);

    //console.log(`Requesting MOT history for: ${valStr}`);

    let bodyFormData = new FormData();
    bodyFormData.set('vrm', valStr);

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_GETMOT}`;
    await axios.post(url, bodyFormData).then(
      (response) => {
        if (response.data !== null) {
          //console.log(response.data);
          setMotDataIsPending(false);

          // Check the status of the incoming response
          const responseDVSA = response.data.status;

          if (responseDVSA === 200) {
            // All looks good - set the data for presentation
            setMotData(response.data.motHistory)
          } else {
            // An error was returned - present to user
            setMotDataError(response.data.errorMessage);
          }
        }
        else {
          setMotDataIsPending(false);
          setMotDataError("An error occurred - please try again later.");
        }
      },
      (error) => {
        // Unable to retrieve data
        setMotDataIsPending(false);
        setMotDataError("An error occurred - please try again later.");
      }
    );
  }

  const onChangeReg = evt => {
    setTextReg(evt.target.value);
  };

  return (
    <div className='mb-5'>
      <MiniHeroSection titleText="MOT Check" sectionClassExtra="mb-3" iconName="fa-car" />
      
      <div className="container">
        <p>
          You can use this service to check past results of a vehicle's MOT test(s), for tests done in England, Scotland or Wales since 2005.
        </p>

        <Alert />

        <div>
          <div className='mb-2'>Registration number (number plate)</div>
          <form>
            <div className='form-row'>
              <div className='col-md-4'>
                <div className='input-group'>
                  <input
                    className='form-control'
                    type='text'
                    name='textSearch'
                    placeholder='Registration...'
                    value={textReg}
                    onChange={onChangeReg}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-md-4 mt-md-0 mt-2'>
                <button
                  className='btn btn-success btn-block'
                  onClick={onClickRequestMOTData}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

        {motDataIsPending && <Spinner />}

        {(motData || motDataError) && (
          <>
            {motDataError && (
              <div className={`alert alert-danger mt-4`}>
                <i className='fas fa-exclamation-circle mr-2' />
                {motDataError}
              </div>
            )}

            {motData && (
              <div className='card border-black mt-4 text-white'>
                <div className='card-body'>
                  <div className='card-text'>
                    {motData.motTests !== null ?
                    (
                      <>
                        {(Utility.getHasMOTExpired(motData.motTests) === true) ?
                          (
                          <div className='alert alert-danger mb-3 d-flex align-items-center' role='alert'>
                            <i className='fas fa-exclamation-triangle fa-2x mr-3' />
                            
                            {(Utility.getMostRecentMOTExpiryDate(motData.motTests) !== null) ? (
                              <strong>This vehicle's MOT expired on {new Date(Utility.getMostRecentMOTExpiryDate(motData.motTests)).toLocaleDateString('en-GB')}.</strong>
                            ) : (
                              <strong>This vehicle's MOT has expired.</strong>
                            )}
                          </div>
                          ) : (
                          <div className='alert alert-success mb-3 d-flex align-items-center' role='alert'>
                            <i className='far fa-check-square fa-2x mr-3' />

                            {(Utility.getMostRecentMOTExpiryDate(motData.motTests) !== null) ? (
                              <strong>This vehicle's MOT expires on {new Date(Utility.getMostRecentMOTExpiryDate(motData.motTests)).toLocaleDateString('en-GB')}.</strong>
                            ) : (
                              <strong>This vehicle has a valid MOT.</strong>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='alert alert-success mb-3 d-flex align-items-center' role='alert'>
                        <i className='far fa-check-square fa-2x mr-3' />
                        <strong>This vehicle's first MOT is due on {new Date(motData.motTestExpiryDate).toLocaleDateString('en-GB')}.</strong>
                      </div>
                    )}

                    <div className='row'>
                      <div className='col-6 col-lg-2'>Registration:</div>
                      <div className='col-6 col-lg-10'>{motData.registration}</div>
                    </div>
                    <div className='row'>
                      <div className='col-6 col-lg-2'>Make:</div>
                      <div className='col-6 col-lg-10'>{motData.make}</div>
                    </div>
                    <div className='row'>
                      <div className='col-6 col-lg-2'>Model:</div>
                      <div className='col-6 col-lg-10'>{motData.model}</div>
                    </div>

                    {motData.firstUsedDate !== null && (
                      <div className='row'>
                        <div className='col-6 col-lg-2'>Registered:</div>
                        <div className='col-6 col-lg-10'>{motData.firstUsedDate}</div>
                      </div>
                    )}

                    {motData.motTestExpiryDate !== null && (
                      <div className='row'>
                        <div className='col-6 col-lg-2'>MOT Expiry Date:</div>
                        <div className='col-6 col-lg-10'>{motData.motTestExpiryDate}</div>
                      </div>
                    )}

                    <div className='row'>
                      <div className='col-6 col-lg-2'>Fuel Type:</div>
                      <div className='col-6 col-lg-10'>{motData.fuelType}</div>
                    </div>
                    <div className='row'>
                      <div className='col-6 col-lg-2'>Primary Colour:</div>
                      <div className='col-6 col-lg-10'>{motData.primaryColour}</div>
                    </div>

                    <div className='row'>
                      <div className='col-6 col-lg-2'># MOT Tests:</div>
                      {motData.motTests !== null ? (
                        <div className='col-6 col-lg-10'>{motData.motTests.length}</div>
                      ) : (
                        <div className='col-6 col-lg-10'>0</div>
                      )}
                    </div>

                    {motData.motTests !== null && (
                      <>
                        {motData.motTests.map((motTest, testIdx) => {
                          const passedTest = (motTest.testResult === 'PASSED');
                          const cardStyle = `mt-3 card text-white ${ passedTest ? 'border-success' : 'border-danger' }`;
                          const iconStyle = `fas fa-${ passedTest ? 'check text-success' : 'times text-danger' }`;

                          return (
                            <div className={cardStyle} key={testIdx}>
                              <div className='card-header position-relative'>
                                <div className='tt-mot-icon-result center-gentle-flex'>
                                  <i className={iconStyle} />
                                </div>

                                <div className='d-flex flex-row'>
                                  <h5 className='mb-0 ml-4'>&nbsp;{motTest.testResult}</h5>
                                  <div><span><small className='ml-2 mr-1'>on</small></span>{motTest.completedDate}</div>
                                </div>
                              </div>                      
                              <div className='card-body bg-dark'>
                                <div className='row'>
                                  <div className='col-6 col-lg-2'>Mileage:</div>
                                  <div className='col-6 col-lg-10'>{Utility.numWithCommas(motTest.odometerValue)}</div>
                                </div>
                                {motTest.expiryDate && passedTest && (
                                  <div className='row'>
                                    <div className='col-6 col-lg-2'>Expires on:</div>
                                    <div className='col-6 col-lg-10'>{motTest.expiryDate}</div>
                                  </div>
                                )}
                                <div className='row'>
                                  <div className='col-6 col-lg-2'>Test Number:</div>
                                  <div className='col-6 col-lg-10'>{motTest.motTestNumber}</div>
                                </div>

                                {(motTest.failureNotes.length > 0) && (
                                  <>
                                    <div className='mt-3 text-danger'>
                                      <i className='mr-2 fas fa-exclamation-triangle' />
                                      <strong>
                                        {Utility.getPluralisedIfNeeded('Reason', motTest.failureNotes.length)} for failures
                                      </strong>
                                    </div>
                                    <ul className='tt-mot-note-ul'>
                                      {motTest.failureNotes.map((note, noteIdx) => {
                                        return (
                                          <li key={noteIdx} className='tt-mot-note-li'>{note}</li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                )}

                                {(motTest.advisoryNotes.length > 0) && (
                                  <>
                                    <div className='mt-3 text-warning'>
                                      <i className='mr-2 fas fa-exclamation-triangle' />
                                      <strong>
                                        Advisory notice {Utility.getPluralisedIfNeeded('item', motTest.advisoryNotes.length)}
                                      </strong>
                                    </div>
                                    <ul className='tt-mot-note-ul'>
                                      {motTest.advisoryNotes.map((note, noteIdx) => {
                                        return (
                                          <li key={noteIdx} className='tt-mot-note-li'>{note}</li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <TTChartsMOTMileage allMOTData={ motData }/>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <BackToTop />
      </div>
    </div>
  );
};

export default MOTCheck;
