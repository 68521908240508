import ChangeLog from "../../change-log";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer bg-primary">
      <div className="footer__nav">
        <Link className="nav-item nav-link" to="/privacy">
          Privacy Policy
        </Link>
        <Link className="nav-item nav-link" to="/contribute">
          Contribute
        </Link>
        <Link className="nav-item nav-link" to="/meettheteam">
          Meet the Team
        </Link>
      </div>
      <div className="container text-muted">
        &copy; {new Date().getFullYear()} Andy Eder
        <div className="ml-3 float-right">
          <small>
            {process.env.REACT_APP_TTNAME} {ChangeLog[0].version}{" "}
            {process.env.REACT_APP_TTVERSION}
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
