export const SEARCH_ALLDATA = "SEARCH_ALLDATA";
export const CLEAR_ALLDATA = "CLEAR_ALLDATA";
export const SEARCH_TROPHY = "SEARCH_TROPHY";
export const CLEAR_TROPHY = "CLEAR_TROPHY";
export const SEARCH_METRICS = "SEARCH_METRICS";
export const CLEAR_METRICS = "CLEAR_METRICS";
export const SET_LOADING = "SET_LOADING";
export const SET_SERVERERROR = "SET_SERVERERROR";
export const REMOVE_SERVERERROR = "REMOVE_SERVERERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REQOP_BY_NUM = "REQOP_BY_NUM";
export const REQOP_BY_VRM = "REQOP_BY_VRM";
export const REQOP_BY_VIN = "REQOP_BY_VIN";
