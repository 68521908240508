import { useEffect, useContext, useState } from "react";
import { useSwipeable } from "react-swipeable";
import GaugeChart from "react-gauge-chart";

import ServerError from "../layout/ServerError";
import SearchMetrics from "../trophys/SearchMetrics";
import Spinner from "../layout/Spinner";
import BackToTop from "../layout/BackToTop";
import Utility from "../util/Utility";
import TTBarChartAllYears from "../containers/TTChartAllYears";
import MiniHeroSection from "../containers/MiniHeroSection";

import TrophysContext from "../../context/trophys/trophysContext";

const Stats = () => {
  const trophysContext = useContext(TrophysContext);
  const { loading, metrics } = trophysContext;

  const [selectedListSellerIndex, setSelectedListSellerIndex] = useState(0);
  const [chartTypeSeller, setChartTypeSeller] = useState("bar");
  const [chartDataDisplaySeller, setChartDataDisplaySeller] = useState("avgPrice");

  const GotoNextSellerYear = () => {
    if (selectedListSellerIndex < metrics.info.listedSellerPricesByYear.length - 1) {
      setSelectedListSellerIndex(selectedListSellerIndex + 1);
    }
  };

  const GotoPrevSellerYear = () => {
    if (selectedListSellerIndex > 0) {
      setSelectedListSellerIndex(selectedListSellerIndex - 1);
    }
  };

  const GotoFirstSellerYear = () => {
    setSelectedListSellerIndex(0);
  };

  const GotoLastSellerYear = () => {
    setSelectedListSellerIndex(metrics.info.listedSellerPricesByYear.length - 1);
  };

  const swipeHandlersSeller = useSwipeable({
    onSwipedLeft: (eventData) => {
      GotoNextSellerYear();
    },

    onSwipedRight: (eventData) => {
      GotoPrevSellerYear();
    },
  });

  const OnChangeChartTypeSeller = (evt) => setChartTypeSeller(evt.target.value);
  const OnChangeChartDataDisplaySeller = (evt) => setChartDataDisplaySeller(evt.target.value);

  //>>

  const [selectedListBuyerIndex, setSelectedListBuyerIndex] = useState(0);
  const [chartTypeBuyer, setChartTypeBuyer] = useState("bar");
  const [chartDataDisplayBuyer, setChartDataDisplayBuyer] = useState("avgPrice");

  const GotoNextBuyerYear = () => {
    if (selectedListBuyerIndex < metrics.info.listedBuyerPricesByYear.length - 1) {
      setSelectedListBuyerIndex(selectedListBuyerIndex + 1);
    }
  };

  const GotoPrevBuyerYear = () => {
    if (selectedListBuyerIndex > 0) {
      setSelectedListBuyerIndex(selectedListBuyerIndex - 1);
    }
  };

  const GotoFirstBuyerYear = () => {
    setSelectedListBuyerIndex(0);
  };

  const GotoLastBuyerYear = () => {
    setSelectedListBuyerIndex(metrics.info.listedBuyerPricesByYear.length - 1);
  };

  const swipeHandlersBuyer = useSwipeable({
    onSwipedLeft: (eventData) => {
      GotoNextBuyerYear();
    },

    onSwipedRight: (eventData) => {
      GotoPrevBuyerYear();
    },
  });

  const OnChangeChartTypeBuyer = (evt) => setChartTypeBuyer(evt.target.value);
  const OnChangeChartDataDisplayBuyer = (evt) => setChartDataDisplayBuyer(evt.target.value);

  //>>

  useEffect(() => {
    !metrics &&
      (async () => {
        await trophysContext.searchMetrics();
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-5">
      <MiniHeroSection titleText="Stats" sectionClassExtra="mb-3" iconName="fa-calculator" />
      
      <div className="container">
        <p>
          Please note that the statistics we provide are based on the data we
          currently hold. We cannot guarantee the accuracy of the data.
        </p>

        <ServerError />
        <SearchMetrics />

        {loading && <Spinner />}

        {metrics && (
          <>
            {!metrics.found ? (
              <div className="card border-warning mt-4 text-white">
                <div className="card-body">
                  <h5 className="card-title">{metrics.info}</h5>
                  <p className="card-text">Please try again later.</p>
                </div>
              </div>
            ) : (
              <div className="mt-3">
                <h3 className="mt-5 text-warning">General</h3>
                <div>General statistics and metrics.</div>

                <div className="row text-center">
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <GaugeChart
                          id="gauge-chart-num-recorded"
                          hideText={true}
                          needleColor="#7F7F7F"
                          needleBaseColor="#9F9F9F"
                          nrOfLevels={25}
                          percent={
                            parseFloat(metrics.info.valueNumTrophys) / 500.0
                          }
                        />
                        <h4 className="text-white">
                          {metrics.info.nameNumTrophys}
                        </h4>
                        <h2 className="text-warning mb-0">
                          {metrics.info.valueNumTrophys}
                          <small style={{ opacity: "0.75" }}>/500</small>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <GaugeChart
                          id="gauge-chart-num-scrapped"
                          hideText={true}
                          nrOfLevels={25}
                          needleColor="#7F7F7F"
                          needleBaseColor="#9F9F9F"
                          percent={
                            parseFloat(metrics.info.valueNumScrapped) / 500.0
                          }
                        />
                        <h4 className="text-white">
                          {metrics.info.nameNumScrapped}
                        </h4>
                        <h2 className="text-warning mb-0">
                          {metrics.info.valueNumScrapped}
                          <small style={{ opacity: "0.75" }}>/500</small>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <h4 className="text-white">
                          {metrics.info.nameEarliestRegistration}
                        </h4>
                        <h4 className="text-warning mb-0">
                          {new Date(
                            metrics.info.valueEarliestRegistration
                          ).toLocaleDateString("en-GB")}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <h4 className="text-white">
                          {metrics.info.nameLatestRegistration}
                        </h4>
                        <h4 className="text-warning mb-0">
                          {new Date(
                            metrics.info.valueLatestRegistration
                          ).toLocaleDateString("en-GB")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col-xs-12 col-sm-12 col-md-4 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <h4 className="text-white">
                          {metrics.info.nameMinMileage}
                        </h4>
                        <h4 className="text-warning mb-0">
                          {Utility.numWithCommas(metrics.info.valueMinMileage)}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <h4 className="text-white">
                          {metrics.info.nameAvgMileage}
                        </h4>
                        <h4 className="text-warning mb-0">
                          {Utility.numWithCommas(metrics.info.valueAvgMileage)}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        <h4 className="text-white">
                          {metrics.info.nameMaxMileage}
                        </h4>
                        <h4 className="text-warning mb-0">
                          {Utility.numWithCommas(metrics.info.valueMaxMileage)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mt-5 text-warning">Seller Price Trends</h3>
                <div className="mb-2 text-white"><strong>The following data is based on known seller listings.</strong></div>
                <div className="mt-2">The table and chart below list the minimum, average (mean) and maximum list sale prices across all years.</div>

                <div className="row text-center">
                  <div className="col-12 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        {metrics.info.listedSellerPricesByYear && (
                          <>
                            <div className="row">
                              <div className="col-12 mb-3 d-flex flex-row justify-content-center align-items-center">
                                <h4 className="mb-0 text-white">
                                  {metrics.info.listedSellerPricesByYear[0].year} -{" "}
                                  {
                                    metrics.info.listedSellerPricesByYear[
                                      metrics.info.listedSellerPricesByYear.length -
                                        1
                                    ].year
                                  }
                                </h4>
                                <h5 className="mb-0 ml-2 text-secondary">
                                  (based on ALL listings)
                                </h5>
                              </div>

                              <div className="col-12">
                                <div className="row mb-2">
                                  <h5 className="col-4 text-warning mb-0">
                                    Min (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Avg (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Max (£)
                                  </h5>
                                </div>

                                <div className="row py-1 bg-dark">
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueMinListSellerPrice
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueAvgListSellerPrice
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueMaxListSellerPrice
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row text-left mt-3 mb-3">
                              <div className="col-6">
                                <div className="mb-1">Chart type:</div>
                                <select
                                  className="custom-select"
                                  defaultValue={chartTypeSeller}
                                  onChange={OnChangeChartTypeSeller}
                                >
                                  <option defaultValue value="bar">
                                    Bar
                                  </option>
                                  <option value="line">Line</option>
                                </select>
                              </div>
                              <div className="col-6">
                                <div className="mb-1">Prices to display:</div>
                                <select
                                  className="custom-select"
                                  defaultValue={chartDataDisplaySeller}
                                  onChange={OnChangeChartDataDisplaySeller}
                                >
                                  <option value="minPrice">Minimum</option>
                                  <option value="avgPrice">Average</option>
                                  <option value="maxPrice">Maximum</option>
                                  <option value="combinedPrice">Combined</option>
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <TTBarChartAllYears
                                  listPricesByYear={
                                    metrics.info.listedSellerPricesByYear
                                  }
                                  chartType={chartTypeSeller}
                                  chartDataDisplay={chartDataDisplaySeller}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">The table below lists the minimum, average (mean) and maximum list sale prices by year. Swipe or use the buttons to cycle through the years.</div>

                <div className="row text-center">
                  <div className="col-12 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        {metrics.info.listedSellerPricesByYear && (
                          <>
                            <div className="row" {...swipeHandlersSeller}>
                              <div className="col-12 mb-3 d-flex flex-row justify-content-center align-items-center">
                                <h4 className="mb-0 text-white">
                                  {
                                    metrics.info.listedSellerPricesByYear[
                                      selectedListSellerIndex
                                    ].year
                                  }
                                </h4>
                                <h5 className="mb-0 ml-2 text-secondary">
                                  (based on{" "}
                                  {Utility.getPluralisedWithValueIfNeeded(
                                    "listing",
                                    metrics.info.listedSellerPricesByYear[
                                      selectedListSellerIndex
                                    ].numListings
                                  )}
                                  )
                                </h5>
                              </div>

                              <div className="col-12">
                                <div className="row mb-2">
                                  <h5 className="col-4 text-warning mb-0">
                                    Min (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Avg (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Max (£)
                                  </h5>
                                </div>

                                <div className="row py-1 bg-dark">
                                  <div className="col-4">
                                    {metrics.info.listedSellerPricesByYear[
                                      selectedListSellerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedSellerPricesByYear[
                                            selectedListSellerIndex
                                          ].minPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {metrics.info.listedSellerPricesByYear[
                                      selectedListSellerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedSellerPricesByYear[
                                            selectedListSellerIndex
                                          ].avgPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {metrics.info.listedSellerPricesByYear[
                                      selectedListSellerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedSellerPricesByYear[
                                            selectedListSellerIndex
                                          ].maxPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                              <div>
                                <button
                                  className="btn btn-dark mr-1"
                                  disabled={selectedListSellerIndex === 0}
                                  onClick={() => GotoFirstSellerYear()}
                                >
                                  <i className="fas fa-step-backward"></i>
                                </button>
                                <button
                                  className="btn btn-dark"
                                  disabled={selectedListSellerIndex === 0}
                                  onClick={() => GotoPrevSellerYear()}
                                >
                                  <i className="fas fa-chevron-circle-left"></i>
                                </button>
                              </div>
                              <div className="text-secondary">
                                <small>
                                  {selectedListSellerIndex + 1} of{" "}
                                  {metrics.info.listedSellerPricesByYear.length}
                                </small>
                              </div>
                              <div>
                                <button
                                  className="btn btn-dark"
                                  disabled={
                                    selectedListSellerIndex >=
                                    metrics.info.listedSellerPricesByYear.length - 1
                                  }
                                  onClick={() => GotoNextSellerYear()}
                                >
                                  <i className="fas fa-chevron-circle-right"></i>
                                </button>
                                <button
                                  className="btn btn-dark ml-1"
                                  disabled={
                                    selectedListSellerIndex >=
                                    metrics.info.listedSellerPricesByYear.length - 1
                                  }
                                  onClick={() => GotoLastSellerYear()}
                                >
                                  <i className="fas fa-step-forward"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-3">
                              <button className="btn btn-secondary" type="button" data-toggle="collapse" data-target="#collapseSellers" aria-expanded="false" aria-controls="collapseSellers">
                                Toggle Listings
                              </button>
                            </div>

                            <div className="collapse" id="collapseSellers">
                              <div className="row mt-3 py-1 bg-info text-dark small">
                                <div className="col-2 font-weight-bold mb-0">#</div>
                                <div className="col-4 font-weight-bold mb-0">Date</div>
                                <div className="col-3 font-weight-bold mb-0">Mileage</div>
                                <div className="col-3 font-weight-bold mb-0">Price (£)</div>
                              </div>

                              {!metrics.info.listedSellerPricesByYear[selectedListSellerIndex].listings && (
                                <div className="row small">
                                  <div className="col-12 py-1 bg-dark text-muted">
                                    No listings
                                  </div>
                                </div>
                              )}

                              {metrics.info.listedSellerPricesByYear[selectedListSellerIndex].listings && (
                                <>
                                  {metrics.info.listedSellerPricesByYear[selectedListSellerIndex].listings.map((listItem, listItemIdx) => {
                                    const bgFill = `row small py-1 ${(listItemIdx % 2 === 0) ? 'bg-dark' : 'bg-primary'}`;
                                    return (
                                      <div className={bgFill} key={listItemIdx}>
                                        <div className="col-2 mb-0">
                                          {listItem.trophyId.toString().padStart(3, "0")}
                                        </div>
                                        <div className="col-4 mb-0">
                                          {new Date(listItem.date).toLocaleDateString('en-GB')}                                          
                                        </div>
                                        <div className="col-3 mb-0">
                                          {listItem.mileage !== "-1" ? (
                                            <>
                                              {Utility.numWithCommas(listItem.mileage)}
                                            </>
                                          ) : (
                                            <div className="text-secondary">No data</div>
                                          )}
                                        </div>
                                        <div className="col-3 mb-0">
                                          {Utility.decimalWithCommas(listItem.price)}                                          
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mt-5 text-warning">Buyer Price Trends</h3>
                <div className="mb-2 text-white"><strong>The following data is based on known purchases.</strong></div>
                <div className="mt-2">The table and chart below list the minimum, average (mean) and maximum purchase prices across all years.</div>

                <div className="row text-center">
                  <div className="col-12 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        {metrics.info.listedBuyerPricesByYear && (
                          <>
                            <div className="row">
                              <div className="col-12 mb-3 d-flex flex-row justify-content-center align-items-center">
                                <h4 className="mb-0 text-white">
                                  {metrics.info.listedBuyerPricesByYear[0].year} -{" "}
                                  {
                                    metrics.info.listedBuyerPricesByYear[
                                      metrics.info.listedBuyerPricesByYear.length -
                                        1
                                    ].year
                                  }
                                </h4>
                                <h5 className="mb-0 ml-2 text-secondary">
                                  (based on ALL listings)
                                </h5>
                              </div>

                              <div className="col-12">
                                <div className="row mb-2">
                                  <h5 className="col-4 text-warning mb-0">
                                    Min (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Avg (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Max (£)
                                  </h5>
                                </div>

                                <div className="row py-1 bg-dark">
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueMinListBuyerPrice
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueAvgListBuyerPrice
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {Utility.decimalWithCommas(
                                      metrics.info.valueMaxListBuyerPrice
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row text-left mt-3 mb-3">
                              <div className="col-6">
                                <div className="mb-1">Chart type:</div>
                                <select
                                  className="custom-select"
                                  defaultValue={chartTypeBuyer}
                                  onChange={OnChangeChartTypeBuyer}
                                >
                                  <option defaultValue value="bar">
                                    Bar
                                  </option>
                                  <option value="line">Line</option>
                                </select>
                              </div>
                              <div className="col-6">
                                <div className="mb-1">Prices to display:</div>
                                <select
                                  className="custom-select"
                                  defaultValue={chartDataDisplayBuyer}
                                  onChange={OnChangeChartDataDisplayBuyer}
                                >
                                  <option value="minPrice">Minimum</option>
                                  <option value="avgPrice">Average</option>
                                  <option value="maxPrice">Maximum</option>
                                  <option value="combinedPrice">Combined</option>
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <TTBarChartAllYears
                                  listPricesByYear={
                                    metrics.info.listedBuyerPricesByYear
                                  }
                                  chartType={chartTypeBuyer}
                                  chartDataDisplay={chartDataDisplayBuyer}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">The table below lists the minimum, average (mean) and maximum purchase prices by year. Swipe or use the buttons to cycle through the years.</div>

                <div className="row text-center">
                  <div className="col-12 mt-3">
                    <div className="card border-secondary">
                      <div className="card-body">
                        {metrics.info.listedBuyerPricesByYear && (
                          <>
                            <div className="row" {...swipeHandlersBuyer}>
                              <div className="col-12 mb-3 d-flex flex-row justify-content-center align-items-center">
                                <h4 className="mb-0 text-white">
                                  {
                                    metrics.info.listedBuyerPricesByYear[
                                      selectedListBuyerIndex
                                    ].year
                                  }
                                </h4>
                                <h5 className="mb-0 ml-2 text-secondary">
                                  (based on{" "}
                                  {Utility.getPluralisedWithValueIfNeeded(
                                    "listing",
                                    metrics.info.listedBuyerPricesByYear[
                                      selectedListBuyerIndex
                                    ].numListings
                                  )}
                                  )
                                </h5>
                              </div>

                              <div className="col-12">
                                <div className="row mb-2">
                                  <h5 className="col-4 text-warning mb-0">
                                    Min (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Avg (£)
                                  </h5>
                                  <h5 className="col-4 text-warning mb-0">
                                    Max (£)
                                  </h5>
                                </div>

                                <div className="row py-1 bg-dark">
                                  <div className="col-4">
                                    {metrics.info.listedBuyerPricesByYear[
                                      selectedListBuyerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedBuyerPricesByYear[
                                            selectedListBuyerIndex
                                          ].minPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {metrics.info.listedBuyerPricesByYear[
                                      selectedListBuyerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedBuyerPricesByYear[
                                            selectedListBuyerIndex
                                          ].avgPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {metrics.info.listedBuyerPricesByYear[
                                      selectedListBuyerIndex
                                    ].numListings > 0 ? (
                                      <>
                                        {Utility.decimalWithCommas(
                                          metrics.info.listedBuyerPricesByYear[
                                            selectedListBuyerIndex
                                          ].maxPrice
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="text-secondary">
                                          No data
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                              <div>
                                <button
                                  className="btn btn-dark mr-1"
                                  disabled={selectedListBuyerIndex === 0}
                                  onClick={() => GotoFirstBuyerYear()}
                                >
                                  <i className="fas fa-step-backward"></i>
                                </button>
                                <button
                                  className="btn btn-dark"
                                  disabled={selectedListBuyerIndex === 0}
                                  onClick={() => GotoPrevBuyerYear()}
                                >
                                  <i className="fas fa-chevron-circle-left"></i>
                                </button>
                              </div>
                              <div className="text-secondary">
                                <small>
                                  {selectedListBuyerIndex + 1} of{" "}
                                  {metrics.info.listedBuyerPricesByYear.length}
                                </small>
                              </div>
                              <div>
                                <button
                                  className="btn btn-dark"
                                  disabled={
                                    selectedListBuyerIndex >=
                                    metrics.info.listedBuyerPricesByYear.length - 1
                                  }
                                  onClick={() => GotoNextBuyerYear()}
                                >
                                  <i className="fas fa-chevron-circle-right"></i>
                                </button>
                                <button
                                  className="btn btn-dark ml-1"
                                  disabled={
                                    selectedListBuyerIndex >=
                                    metrics.info.listedBuyerPricesByYear.length - 1
                                  }
                                  onClick={() => GotoLastBuyerYear()}
                                >
                                  <i className="fas fa-step-forward"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-3">
                              <button className="btn btn-secondary" type="button" data-toggle="collapse" data-target="#collapseBuyers" aria-expanded="false" aria-controls="collapseBuyers">
                                Toggle Listings
                              </button>
                            </div>

                            <div className="collapse" id="collapseBuyers">
                              <div className="row mt-3 py-1 bg-info text-dark small">
                                <div className="col-2 font-weight-bold mb-0">#</div>
                                <div className="col-4 font-weight-bold mb-0">Date</div>
                                <div className="col-3 font-weight-bold mb-0">Mileage</div>
                                <div className="col-3 font-weight-bold mb-0">Price (£)</div>
                              </div>

                              {!metrics.info.listedBuyerPricesByYear[selectedListBuyerIndex].listings && (
                                <div className="row small">
                                  <div className="col-12 py-1 bg-dark text-muted">
                                    No listings
                                  </div>
                                </div>
                              )}

                              {metrics.info.listedBuyerPricesByYear[selectedListBuyerIndex].listings && (
                                <>
                                  {metrics.info.listedBuyerPricesByYear[selectedListBuyerIndex].listings.map((listItem, listItemIdx) => {
                                    const bgFill = `row small py-1 ${(listItemIdx % 2 === 0) ? 'bg-dark' : 'bg-primary'}`;
                                    return (
                                      <div className={bgFill} key={listItemIdx}>
                                        <div className="col-2 mb-0">
                                          {listItem.trophyId.toString().padStart(3, "0")}
                                        </div>
                                        <div className="col-4 mb-0">
                                          {new Date(listItem.date).toLocaleDateString('en-GB')}                                          
                                        </div>
                                        <div className="col-3 mb-0">
                                          {listItem.mileage !== "-1" ? (
                                            <>
                                              {Utility.numWithCommas(listItem.mileage)}
                                            </>
                                          ) : (
                                            <div className="text-secondary">No data</div>
                                          )}
                                        </div>
                                        <div className="col-3 mb-0">
                                          {Utility.decimalWithCommas(listItem.price)}                                          
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <BackToTop />
      </div>
    </div>
  );
};

export default Stats;
