import { useState } from 'react';
import axios from 'axios';

import Spinner from '../layout/Spinner';
import Utility from '../util/Utility';
import TTChartsMOTMileage from './TTChartsMOTMileage';

const TTMOTContainer = ({ trophyInfo }) => {
  const trophyOriginalReg = trophyInfo.originalReg;
  const trophyLastKnownReg = trophyInfo.lastKnownReg;

  const [motData, setMotData] = useState(null);
  const [motDataIsPending, setMotDataIsPending] = useState(false);
  const [motDataError, setMotDataError] = useState(null);

  const onClickRequestMOTData = async () => {
    setMotData(null);
    setMotDataIsPending(true);
    setMotDataError(null);

    let regToUse = null;

    if ((trophyOriginalReg !== null) && (trophyLastKnownReg !== null)) {
      regToUse = trophyLastKnownReg.replace(/\s+/g, '');

      if (regToUse.length > 7) {
        regToUse = trophyOriginalReg.replace(/\s+/g, '');
      }
    } else {
      regToUse = ((trophyOriginalReg !== null) ? (trophyOriginalReg) : (trophyLastKnownReg)).replace(/\s+/g, '');
    }

    //console.log(`Requesting MOT history for: ${regToUse}`);

    let bodyFormData = new FormData();
    bodyFormData.set('vrm', regToUse);

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_GETMOT}`;
    await axios.post(url, bodyFormData).then(
      (response) => {
        if (response.data !== null) {
          //console.log(response.data);
          setMotDataIsPending(false);

          // Check the status of the incoming response
          const responseDVSA = response.data.status;

          if (responseDVSA === 200) {
            // All looks good - set the data for presentation
            setMotData(response.data.motHistory)
          } else {
            // An error was returned - present to user
            setMotDataError(response.data.errorMessage);
          }
        }
        else {
          setMotDataIsPending(false);
          setMotDataError("An error occurred - please try again later.");
        }
      },
      (error) => {
        // Unable to retrieve data
        setMotDataIsPending(false);
        setMotDataError("An error occurred - please try again later.");
      }
    );
  }
  
  return (
    <>
      <h3 className='tt-heading text-warning'>MOT History</h3>
      <div className='mb-5'>
        {(!trophyOriginalReg && !trophyLastKnownReg) && (
          <div className='text-white'>(No data available)</div>
        )}

        {(trophyOriginalReg || trophyLastKnownReg) && (
          <>
            {/* Show MOT data */}
            <div className='text-white mb-3'><i className='fas fa-info-circle mr-2 text-secondary' />(Data courtesy of the DVSA)</div>

            {motDataIsPending && <Spinner />}

            {motDataError && (
              <div className={`alert alert-danger`}>
                <i className='fas fa-exclamation-circle mr-2' />
                {motDataError}
              </div>
            )}

            {/* Show button to request data if it isn't available */}
            {!motData && !motDataIsPending && (
              <form>
                <div className='form-row'>
                  <div className='col-md-4'>
                    <button
                      type='button'
                      className='btn btn-secondary btn-block'
                      onClick={onClickRequestMOTData}
                    >
                      Request History
                    </button>
                  </div>
                </div>
              </form>            
            )}

            {motData && (
              <div className='mb-3'>

                {(Utility.getHasMOTExpired(motData.motTests) === true) ?
                  (
                  <div className='alert alert-danger mb-3 d-flex align-items-center' role='alert'>
                    <i className='fas fa-exclamation-triangle fa-2x mr-3' />
                    
                    {(Utility.getMostRecentMOTExpiryDate(motData.motTests) !== null) ? (
                      <strong>This vehicle's MOT expired on {new Date(Utility.getMostRecentMOTExpiryDate(motData.motTests)).toLocaleDateString('en-GB')}.</strong>
                    ) : (
                      <strong>This vehicle's MOT has expired.</strong>
                    )}
                  </div>
                  ) : (
                  <div className='alert alert-success mb-3 d-flex align-items-center' role='alert'>
                    <i className='far fa-check-square fa-2x mr-3' />

                    {(Utility.getMostRecentMOTExpiryDate(motData.motTests) !== null) ? (
                      <strong>This vehicle's MOT expires on {new Date(Utility.getMostRecentMOTExpiryDate(motData.motTests)).toLocaleDateString('en-GB')}.</strong>
                    ) : (
                      <strong>This vehicle has a valid MOT.</strong>
                    )}
                  </div>
                )}

                {motData.motTestExpiryDate !== null &&
                  (
                  <div className='alert alert-danger mb-3' role='alert'>
                    <i className='fas fa-exclamation-triangle mr-2' />
                    <strong>This vehicle's MOT is overdue.</strong>
                  </div>
                )}

                <div className='row'>
                  <div className='col-6 col-lg-2'>Registration:</div>
                  <div className='col-6 col-lg-10'>{motData.registration}</div>
                </div>
                <div className='row'>
                  <div className='col-6 col-lg-2'>Make:</div>
                  <div className='col-6 col-lg-10'>{motData.make}</div>
                </div>
                <div className='row'>
                  <div className='col-6 col-lg-2'>Model:</div>
                  <div className='col-6 col-lg-10'>{motData.model}</div>
                </div>

                {motData.firstUsedDate !== null && (
                  <div className='row'>
                    <div className='col-6 col-lg-2'>Registered:</div>
                    <div className='col-6 col-lg-10'>{motData.firstUsedDate}</div>
                  </div>
                )}

                {motData.motTestExpiryDate !== null && (
                  <div className='row'>
                    <div className='col-6 col-lg-2'>MOT Expiry Date:</div>
                    <div className='col-6 col-lg-10'>{motData.motTestExpiryDate}</div>
                  </div>
                )}

                <div className='row'>
                  <div className='col-6 col-lg-2'>Fuel Type:</div>
                  <div className='col-6 col-lg-10'>{motData.fuelType}</div>
                </div>
                <div className='row'>
                  <div className='col-6 col-lg-2'>Primary Colour:</div>
                  <div className='col-6 col-lg-10'>{motData.primaryColour}</div>
                </div>

                <div className='row'>
                  <div className='col-6 col-lg-2'># MOT Tests:</div>
                  {motData.motTests !== null ? (
                    <div className='col-6 col-lg-10'>{motData.motTests.length}</div>
                  ) : (
                    <div className='col-6 col-lg-10'>0</div>
                  )}
                </div>

                {motData.motTests !== null && (
                  <>
                    <TTChartsMOTMileage allMOTData={ motData }/>

                    {motData.motTests.map((motTest, testIdx) => {
                      const passedTest = (motTest.testResult === 'PASSED');
                      const cardStyle = `mt-3 card text-white ${ passedTest ? 'border-success' : 'border-danger' }`;
                      const iconStyle = `fas fa-${ passedTest ? 'check text-success' : 'times text-danger' }`;

                      return (
                        <div className={cardStyle} key={testIdx}>
                          <div className='card-header position-relative'>
                            <div className='tt-mot-icon-result center-gentle-flex'>
                              <i className={iconStyle} />
                            </div>

                            <div className='d-flex flex-row'>
                              <h5 className='mb-0 ml-4'>&nbsp;{motTest.testResult}</h5>
                              <div><span><small className='ml-2 mr-1'>on</small></span>{motTest.completedDate}</div>
                            </div>
                          </div>                      
                          <div className='card-body bg-dark'>
                            <div className='row'>
                              <div className='col-6 col-lg-2'>Mileage:</div>
                              <div className='col-6 col-lg-10'>{Utility.numWithCommas(motTest.odometerValue)}</div>
                            </div>
                            {motTest.expiryDate && passedTest && (
                              <div className='row'>
                                <div className='col-6 col-lg-2'>Expires on:</div>
                                <div className='col-6 col-lg-10'>{motTest.expiryDate}</div>
                              </div>
                            )}
                            <div className='row'>
                              <div className='col-6 col-lg-2'>Test Number:</div>
                              <div className='col-6 col-lg-10'>{motTest.motTestNumber}</div>
                            </div>

                            {(motTest.failureNotes.length > 0) && (
                              <>
                                <div className='mt-3 text-danger'>
                                  <i className='mr-2 fas fa-exclamation-triangle' />
                                  <strong>
                                    {Utility.getPluralisedIfNeeded('Reason', motTest.failureNotes.length)} for failures
                                  </strong>
                                </div>
                                <ul className='tt-mot-note-ul'>
                                  {motTest.failureNotes.map((note, noteIdx) => {
                                    return (
                                      <li key={noteIdx} className='tt-mot-note-li'>{note}</li>
                                    );
                                  })}
                                </ul>
                              </>
                            )}

                            {(motTest.advisoryNotes.length > 0) && (
                              <>
                                <div className='mt-3 text-warning'>
                                  <i className='mr-2 fas fa-exclamation-triangle' />
                                  <strong>
                                    Advisory notice {Utility.getPluralisedIfNeeded('item', motTest.advisoryNotes.length)}
                                  </strong>
                                </div>
                                <ul className='tt-mot-note-ul'>
                                  {motTest.advisoryNotes.map((note, noteIdx) => {
                                    return (
                                      <li key={noteIdx} className='tt-mot-note-li'>{note}</li>
                                    );
                                  })}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
 
export default TTMOTContainer;