const TTSignatureItemsContainer = ({ trophyInfo }) => {
  const iconYes = 'fas fa-check fa-2x text-success';
  const iconNo = 'fas fa-times fa-2x text-danger';
  const iconUnknown = 'fas fa-question fa-2x text-warning';

  const cssHasSachs = (trophyInfo.hasSachs) ? ((trophyInfo.hasSachs === '1') ? (iconYes) : (iconNo)) : (iconUnknown);
  const cssHasTrendlines = (trophyInfo.hasTrendlines) ? ((trophyInfo.hasTrendlines === '1') ? (iconYes) : (iconNo)) : (iconUnknown);
  const cssHasSpoiler = (trophyInfo.hasSpoiler) ? ((trophyInfo.hasSpoiler === '1') ? (iconYes) : (iconNo)) : (iconUnknown);
  const cssHasTurinis = (trophyInfo.hasTurinis) ? ((trophyInfo.hasTurinis === '1') ? (iconYes) : (iconNo)) : (iconUnknown);

  return (
    <>
      <h3 className="tt-heading text-warning">Signature Items</h3>
      <div className="mb-3">An indication as to whether or not the key signature items are present on this vehicle.</div>
      <div className="mb-5 signature-items">
        <div className="signature-items__container">
          <div className="signature-items__item">
            <div className="signature-items__item-text">Sachs Dampers?</div>
            <div className="signature-items__item-icon mt-2"><i className={cssHasSachs} /></div>
          </div>
          <div className="signature-items__item">
            <div className="signature-items__item-text">Recaro Trendlines?</div>
            <div className="signature-items__item-icon mt-2"><i className={cssHasTrendlines} /></div>
          </div>
          <div className="signature-items__item">
            <div className="signature-items__item-text">V6 Roof Spoiler?</div>
            <div className="signature-items__item-icon mt-2"><i className={cssHasSpoiler} /></div>
          </div>
          <div className="signature-items__item">
            <div className="signature-items__item-text">Trophy Turinis?</div>
            <div className="signature-items__item-icon mt-2"><i className={cssHasTurinis} /></div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default TTSignatureItemsContainer;