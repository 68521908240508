const MiniHeroSection = ({ titleText, sectionClassExtra, iconName }) => {
  const classContent = "mini-hero-section pt-5 pb-3" + (((sectionClassExtra != null) && (sectionClassExtra.length > 0)) ? " " + (sectionClassExtra) : (""));
  const classIconContent = "mini-hero-overlay fas fa-3x" + (((iconName != null) && (iconName.length > 0)) ? " " + (iconName) : (""));

  return (
    <section className={classContent}>
      <div className="container mini-hero-container">
        <h2>{titleText}</h2>
        <i className={classIconContent} />
      </div>
    </section>
  );
};

export default MiniHeroSection;
