class Utility {
  static trophyIdOpts = [];
  static dateDays = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
  ];
  static dateMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  static dateRegYears = ['2005', '2006'];
  static optionsUnknownYesNo = ['Unknown', 'Yes', 'No'];
  static optionsCountries = [
    'Australia',
    'England',
    'France',
    'Hong Kong',
    'Ireland',
    'Northern Ireland',
    'Scotland',
    'Spain',
    'Wales'
  ];
  static optionsAreasUnlisted = ['Unknown', 'Other'];
  static optionsAreasEngland = [
    'Bath and North East Somerset',
    'Bedfordshire',
    'Berkshire',
    'Bristol',
    'Buckinghamshire',
    'Cambridgeshire',
    'Cheshire',
    'Cornwall',
    'County Durham',
    'Cumbria',
    'Derbyshire',
    'Devon',
    'Dorset',
    'East Riding of Yorkshire',
    'East Sussex',
    'Essex',
    'Gloucestershire',
    'Greater London',
    'Greater Manchester',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Isle of Wight',
    'Isles of Scilly',
    'Kent',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'Merseyside',
    'Norfolk',
    'North Somerset',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Oxfordshire',
    'Rutland',
    'Shropshire',
    'Somerset',
    'South Gloucestershire',
    'South Yorkshire',
    'Staffordshire',
    'Suffolk',
    'Surrey',
    'Tyne & Wear',
    'Warwickshire',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Wiltshire',
    'Worcestershire'
  ];
  static optionsAreasNI = [
    'Antrim',
    'Armagh',
    'Down',
    'Fermanagh',
    'Londonderry',
    'Tyrone'
  ];
  static optionsAreasScotland = [
    'Aberdeenshire',
    'Angus',
    'Argyll & Bute',
    'Ayrshire',
    'Banffshire',
    'Berwickshire',
    'Borders',
    'Caithness',
    'Clackmannanshire',
    'Dumfries & Galloway',
    'Dunbartonshire',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'Fife',
    'Highland',
    'Inverclyde',
    'Kincardineshire',
    'Lanarkshire',
    'Midlothian',
    'Moray',
    'North Ayrshire',
    'North Lanarkshire',
    'Orkney',
    'Perth & Kinross',
    'Renfrewshire',
    'Shetland',
    'South Ayrshire',
    'South Lanarkshire',
    'Stirlingshire',
    'West Dunbartonshire',
    'West Lothian',
    'Western Isles'
  ];
  static optionsAreasWales = [
    'Blaenau Gwent',
    'Bridgend',
    'Caerphilly',
    'Cardiff',
    'Carmarthenshire',
    'Ceredigion',
    'Conwy',
    'Denbighshire',
    'Flintshire',
    'Gwynedd',
    'Isle of Anglesey',
    'Merthyr Tydfil',
    'Monmouthshire',
    'Neath Port Talbot',
    'Newport',
    'Pembrokeshire',
    'Powys',
    'Rhondda Cynon Taff',
    'Swansea',
    'Torfaen',
    'Vale of Glamorgan',
    'Wrexham'
  ];

  static eventTypeIcons = [
    { icon: 'fas fa-question-circle', backColour: 'rgb(29, 33, 38)', colour: '#7f7f7f' },  // Unknown Event Type
    { icon: 'fas fa-road', backColour: 'rgb(0, 96, 0)', colour: '#ffffff' },               // Car Registered
    { icon: 'fas fa-trash', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },            // Car Scrapped
    { icon: 'fas fa-car-crash', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },        // Car Written-Off
    { icon: 'fas fa-check', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },            // MOT - Passed
    { icon: 'fas fa-times', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },            // MOT - Failed
    { icon: 'fas fa-pound-sign', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },       // Listed For Sale
    { icon: 'fas fa-user', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },             // Ownership Changed
    { icon: 'fas fa-exchange-alt', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' },     // VRM changed
    { icon: 'fas fa-share', backColour: 'rgb(29, 33, 38)', colour: '#ffffff' }             // Car Exported
  ];

  static numWithCommas = valIn => {
    const intlFormatter = new Intl.NumberFormat('en-gb', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    });

    return intlFormatter.format(valIn);
  };

  static currencyWithCommas = valIn => {
    const intlFormatter = new Intl.NumberFormat('en-gb', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return intlFormatter.format(valIn);
  };

  static decimalWithCommas = valIn => {
    const intlFormatter = new Intl.NumberFormat('en-gb', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return intlFormatter.format(valIn);
  };

  static getPluralisedIfNeeded = (singularString, value) => {
    return singularString + (value === 1 || value === '1' ? '' : 's');
  };

  static getPluralisedWithValueIfNeeded = (singularString, value) => {
    return Number(value) + ' ' + singularString + (value === 1 || value === '1' ? '' : 's');
  };

  static getPluralisedYIESWithValueIfNeeded = (singularString, value) => {
    return Number(value) + ' ' + singularString + (value === 1 || value === '1' ? 'y' : 'ies');
  };

  static dateDiffInDays = (date0, date1) => {
    const dt0 = new Date(date0);
    const dt1 = new Date(date1);
    return Math.floor(
      (Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) -
        Date.UTC(dt0.getFullYear(), dt0.getMonth(), dt0.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  };

  static dateDiffInDaysString = (date0, date1) => {
    const delta = this.dateDiffInDays(date0, date1);
    if (delta === 0) {
      return 'today';
    }

    return this.numWithCommas(delta) + ' ' + Utility.getPluralisedIfNeeded('day', delta) + ' ago';
  };

  static roundUpToNearest = (num, factor = 1) => {
    const quotient = num / factor;
    const res = Math.ceil(quotient) * factor;
    return res;
  };

  static getTrophyIdOpts = () => {
    if (this.trophyIdOpts.length === 0) {
      for (let idx = 1; idx <= 500; idx++) {
        this.trophyIdOpts.push(idx);
      }
    }

    return this.trophyIdOpts;
  };

  static getDateDays = () => {
    return this.dateDays;
  };

  static getDateMonths = () => {
    return this.dateMonths;
  };

  static getDateRegYears = () => {
    return this.dateRegYears;
  };

  static getOptionsUnknownYesNo = () => {
    return this.optionsUnknownYesNo;
  };

  static getOptionsCountries = () => {
    return this.optionsCountries;
  };

  static getOptionsAreasUnlisted = () => {
    return this.optionsAreasUnlisted;
  };

  static getOptionsAreasEngland = () => {
    return this.optionsAreasEngland;
  };

  static getOptionsAreasNI = () => {
    return this.optionsAreasNI;
  };

  static getOptionsAreasScotland = () => {
    return this.optionsAreasScotland;
  };

  static getOptionsAreasWales = () => {
    return this.optionsAreasWales;
  };

  static getIconForEventType = (eventTypeId) => {
    return this.eventTypeIcons[eventTypeId.eventTypeId];
  };

  static getMostRecentMOTExpiryDate = (motTests) => {
    let mostRecentExpiryAsMs = 0;

    if (motTests !== null) {
      motTests.forEach(motTest => {
        if (motTest.expiryDate !== null) {
          let expiryDateAsMs = Date.parse(motTest.expiryDate);
          if (expiryDateAsMs > mostRecentExpiryAsMs) {
            mostRecentExpiryAsMs = expiryDateAsMs;
          }
        }
      });

      return mostRecentExpiryAsMs;
    }

    return null;
  };

  static getHasMOTExpired = (motTests) => {
    let now = new Date();
    let nowAsMs = Date.parse(now);
    let mostRecentExpiryAsMs = this.getMostRecentMOTExpiryDate(motTests);

    if (mostRecentExpiryAsMs !== null)
    {
      return (mostRecentExpiryAsMs < nowAsMs);
    }

    return false;
  };
}

export default Utility;
