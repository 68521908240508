const BiogCard = (props) => {
  return (
    <div className="biog-card">
      <div className="mt-3">
        <img className="biog-card__img" src={props.imgUrl} alt="{props.name}" />
        <h4 className="text-white">{props.name}</h4>
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default BiogCard;
