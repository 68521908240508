import { Fragment, useState } from "react";

import axios from "axios";

import BackToTop from "../layout/BackToTop";
import MiniHeroSection from "../containers/MiniHeroSection";
import Utility from "../util/Utility";

const Submit = () => {
  //let areas = Utility.getOptionsAreasUnlisted();

  const [svrReply, setSvrReply] = useState(null);
  const [textName, setTextName] = useState("");
  const [textEmail, setTextEmail] = useState("");
  const [textTrophyId, setTextTrophyId] = useState("");
  const [textRegDay, setTextRegDay] = useState("");
  const [textRegMonth, setTextRegMonth] = useState("");
  const [textRegYear, setTextRegYear] = useState("");
  const [textLocCountry, setTextLocCountry] = useState(
    Utility.getOptionsAreasUnlisted()[0]
  );
  const [textLocArea, setTextLocArea] = useState(
    Utility.getOptionsAreasUnlisted()[0]
  );
  const [textVRMOriginal, setTextVRMOriginal] = useState("");
  const [textVRMCurrent, setTextVRMCurrent] = useState("");
  const [textVIN, setTextVIN] = useState("");
  const [textMileage, setTextMileage] = useState("");
  const [textScrapped, setTextScrapped] = useState(
    Utility.getOptionsUnknownYesNo()[0]
  );
  const [textSpecDampers, setTextSpecDampers] = useState(
    Utility.getOptionsUnknownYesNo()[0]
  );
  const [textSpecTrendlines, setTextSpecTrendlines] = useState(
    Utility.getOptionsAreasUnlisted()[0]
  );
  const [textSpecSpoiler, setTextSpecSpoiler] = useState(
    Utility.getOptionsUnknownYesNo()[0]
  );
  const [textSpecTurinis, setTextSpecTurinis] = useState(
    Utility.getOptionsUnknownYesNo()[0]
  );
  const [textAddNotes, setTextAddNotes] = useState("");

  const onSubmitMsg = async (evt) => {
    evt.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.set("inName", textName);
    bodyFormData.set("inEmail", textEmail);
    bodyFormData.set("inTrophyId", textTrophyId);
    bodyFormData.set("inRegDay", textRegDay);
    bodyFormData.set("inRegMonth", textRegMonth);
    bodyFormData.set("inRegYear", textRegYear);
    bodyFormData.set("inLocCountry", textLocCountry);
    bodyFormData.set("inLocArea", textLocArea);
    bodyFormData.set("inVRMOriginal", textVRMOriginal);
    bodyFormData.set("inVRMCurrent", textVRMCurrent);
    bodyFormData.set("inVIN", textVIN);
    bodyFormData.set("inMileage", textMileage);
    bodyFormData.set("inScrapped", textScrapped);
    bodyFormData.set("inSpecDampers", textSpecDampers);
    bodyFormData.set("inSpecTrendlines", textSpecTrendlines);
    bodyFormData.set("inSpecSpoiler", textSpecSpoiler);
    bodyFormData.set("inSpecTurinis", textSpecTurinis);
    bodyFormData.set("inAddNotes", textAddNotes);

    const url = `${process.env.REACT_APP_TTPHP_PROVIDER}`;
    const res = await axios.post(url, bodyFormData);

    if (res.status === 200) {
      setSvrReply(res.data);
    }
  };

  const onChangeName = (evt) => setTextName(evt.target.value);
  const onChangeEmail = (evt) => setTextEmail(evt.target.value);
  const onChangeTrophyId = (evt) => setTextTrophyId(evt.target.value);

  const onChangeLocCountry = (evt) => {
    const val = evt.target.value;
    setTextLocCountry(val);
    /*switch (val) {
      case 'England':
        areas = Utility.getOptionsAreasEngland();
        break;
      case 'Northern Ireland':
        areas = Utility.getOptionsAreasNI();
        break;
      case 'Scotland':
        areas = Utility.getOptionsAreasScotland();
        break;
      case 'Wales':
        areas = Utility.getOptionsAreasWales();
        break;
      default:
        areas = Utility.getOptionsAreasUnlisted();
        break;
    }
    console.log(areas);*/
  };

  const onChangeLocArea = (evt) => setTextLocArea(evt.target.value);
  const onChangeRegDay = (evt) => setTextRegDay(evt.target.value);
  const onChangeRegMonth = (evt) => setTextRegMonth(evt.target.value);
  const onChangeRegYear = (evt) => setTextRegYear(evt.target.value);
  const onChangeVRMOriginal = (evt) => setTextVRMOriginal(evt.target.value);
  const onChangeVRMCurrent = (evt) => setTextVRMCurrent(evt.target.value);
  const onChangeVIN = (evt) => setTextVIN(evt.target.value);
  const onChangeMileage = (evt) => setTextMileage(evt.target.value);
  const onChangeScrapped = (evt) => setTextScrapped(evt.target.value);
  const onChangeSpecDampers = (evt) => setTextSpecDampers(evt.target.value);
  const onChangeSpecTrendlines = (evt) =>
    setTextSpecTrendlines(evt.target.value);
  const onChangeSpecSpoiler = (evt) => setTextSpecSpoiler(evt.target.value);
  const onChangeSpecTurinis = (evt) => setTextSpecTurinis(evt.target.value);
  const onChangeAddNotes = (evt) => setTextAddNotes(evt.target.value);

  return (
    <div className="mb-5">
      <MiniHeroSection titleText="Submit Info" sectionClassExtra="mb-3" iconName="fa-car" />
      
      <div className="container">
        {svrReply === null ? (
          <Fragment>
            <p>
              Please use this form to provide information for a specific Trophy.
            </p>

            <form onSubmit={onSubmitMsg}>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="inputName" className="tt-label-submit-form">
                    Your Name <span className="text-warning">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Name..."
                    name="textName"
                    value={textName}
                    onChange={onChangeName}
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail" className="tt-label-submit-form">
                    Your E-mail <span className="text-warning">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="E-mail..."
                    name="textEmail"
                    value={textEmail}
                    onChange={onChangeEmail}
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="inputTrophyId" className="tt-label-submit-form">
                    Trophy Number <span className="text-warning">*</span>
                  </label>
                  <select
                    className="form-control custom-select"
                    id="inputTrophyId"
                    name="textTrophyId"
                    onChange={onChangeTrophyId}
                    required
                  >
                    <option value="">Trophy Number...</option>
                    {Utility.getTrophyIdOpts().map((trophyId, trophyIdx) => {
                      return (
                        <option key={trophyIdx} value={trophyId}>
                          {trophyId}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="tt-label-submit-form">
                    Date of Registration
                  </label>
                  <div className="form-row">
                    <div className="col-4">
                      <label
                        htmlFor="inputRegDay"
                        className="tt-label-submit-form"
                      >
                        Day
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputRegDay"
                        name="textRegDay"
                        defaultValue=""
                        onChange={onChangeRegDay}
                      >
                        <option value="">Day...</option>
                        {Utility.getDateDays().map((dayId, dayIdx) => {
                          return (
                            <option key={dayIdx} value={dayId}>
                              {dayId}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-4">
                      <label
                        htmlFor="inputRegMonth"
                        className="tt-label-submit-form"
                      >
                        Month
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputRegMonth"
                        name="textRegMonth"
                        defaultValue=""
                        onChange={onChangeRegMonth}
                      >
                        <option value="">Month...</option>
                        {Utility.getDateMonths().map((monthId, monthIdx) => {
                          return (
                            <option key={monthIdx} value={monthId}>
                              {monthId}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-4">
                      <label
                        htmlFor="inputRegYear"
                        className="tt-label-submit-form"
                      >
                        Year
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputRegYear"
                        name="textRegYear"
                        defaultValue=""
                        onChange={onChangeRegYear}
                      >
                        <option value="">Year...</option>
                        {Utility.getDateRegYears().map((yearId, yearIdx) => {
                          return (
                            <option key={yearIdx} value={yearId}>
                              {yearId}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="tt-label-submit-form">Location</label>
                  <div className="mb-2 text-info">
                    If your Country / Principal Area is not listed here then you
                    can enter them in the Additional Notes section below.
                  </div>
                  <div className="form-row">
                    <div className="col-6">
                      <label
                        htmlFor="inputLocCountry"
                        className="tt-label-submit-form"
                      >
                        Country
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputLocCountry"
                        name="textLocCountry"
                        defaultValue={textLocCountry}
                        onChange={onChangeLocCountry}
                      >
                        <option disabled>- Unlisted -</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Other">Other</option>
                        <option disabled>- Countries -</option>
                        {Utility.getOptionsCountries().map(
                          (country, countryIdx) => {
                            return (
                              <option key={countryIdx} value={country}>
                                {country}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="inputLocArea"
                        className="tt-label-submit-form"
                      >
                        Principal Area
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputLocArea"
                        name="textLocArea"
                        defaultValue={textLocCountry}
                        onChange={onChangeLocArea}
                      >
                        {/*
                        {areas.map((area, areaIdx) => {
                          return (
                            <option key={areaIdx} value={area}>
                              {area}
                            </option>
                          );
                        })}*/}

                        <option disabled>- Unlisted -</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Other">Other</option>
                        <option disabled>- England -</option>
                        {Utility.getOptionsAreasEngland().map((area, areaIdx) => {
                          return (
                            <option key={areaIdx} value={area}>
                              {area}
                            </option>
                          );
                        })}
                        <option disabled>- Northern Ireland -</option>
                        {Utility.getOptionsAreasNI().map((area, areaIdx) => {
                          return (
                            <option key={areaIdx} value={area}>
                              {area}
                            </option>
                          );
                        })}
                        <option disabled>- Scotland -</option>
                        {Utility.getOptionsAreasScotland().map(
                          (area, areaIdx) => {
                            return (
                              <option key={areaIdx} value={area}>
                                {area}
                              </option>
                            );
                          }
                        )}
                        <option disabled>- Wales -</option>
                        {Utility.getOptionsAreasWales().map((area, areaIdx) => {
                          return (
                            <option key={areaIdx} value={area}>
                              {area}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="tt-label-submit-form">
                    Vehicle Registration Mark (Reg Plate)
                  </label>
                  <div className="form-row">
                    <div className="col-6">
                      <label
                        htmlFor="inputVRMOriginal"
                        className="tt-label-submit-form"
                      >
                        Original Plate
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputVRMOriginal"
                        placeholder="Original..."
                        name="textVRMOriginal"
                        value={textVRMOriginal}
                        onChange={onChangeVRMOriginal}
                      />
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="inputVRMCurrent"
                        className="tt-label-submit-form"
                      >
                        Current Plate
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputVRMCurrent"
                        placeholder="Current..."
                        name="textVRMCurrent"
                        value={textVRMCurrent}
                        onChange={onChangeVRMCurrent}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="tt-label-submit-form">
                    Further Information
                  </label>
                  <div className="form-row">
                    <div className="col-6">
                      <label htmlFor="inputVIN" className="tt-label-submit-form">
                        VIN (Chassis Number)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputVIN"
                        placeholder="VIN..."
                        name="textVIN"
                        value={textVIN}
                        onChange={onChangeVIN}
                      />
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="inputMileage"
                        className="tt-label-submit-form"
                      >
                        Current Mileage
                      </label>
                      <input
                        type="number"
                        min="0"
                        max="999999"
                        className="form-control"
                        id="inputMileage"
                        placeholder="Miles..."
                        name="textMileage"
                        value={textMileage}
                        onChange={onChangeMileage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <div className="form-row">
                    <div className="col-6">
                      <label
                        htmlFor="inputScrapped"
                        className="tt-label-submit-form"
                      >
                        Has It Been Scrapped?
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputScrapped"
                        name="textScrapped"
                        defaultValue={textScrapped}
                        onChange={onChangeScrapped}
                      >
                        {Utility.getOptionsUnknownYesNo().map(
                          (stateScrapped, stateScrappedIdx) => {
                            return (
                              <option
                                key={stateScrappedIdx}
                                value={stateScrapped}
                              >
                                {stateScrapped}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-6"></div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="tt-label-submit-form">
                    Does This Trophy Have...?
                  </label>
                  <div className="form-row">
                    <div className="col-6">
                      <label
                        htmlFor="inputSpecDampers"
                        className="tt-label-submit-form"
                      >
                        Front Sachs Dampers
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputSpecDampers"
                        name="textSpecDampers"
                        defaultValue={textSpecDampers}
                        onChange={onChangeSpecDampers}
                      >
                        {Utility.getOptionsUnknownYesNo().map(
                          (state, stateIdx) => {
                            return (
                              <option key={stateIdx} value={state}>
                                {state}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="inputRegYear"
                        className="tt-label-submit-form"
                      >
                        Recaro Trendline Seats
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputSpecTrendlines"
                        name="textSpecTrendlines"
                        defaultValue={textSpecTrendlines}
                        onChange={onChangeSpecTrendlines}
                      >
                        {Utility.getOptionsUnknownYesNo().map(
                          (state, stateIdx) => {
                            return (
                              <option key={stateIdx} value={state}>
                                {state}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <div className="form-row">
                    <div className="col-6">
                      <label
                        htmlFor="inputSpecSpoiler"
                        className="tt-label-submit-form"
                      >
                        Roof Spoiler
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputSpecSpoiler"
                        name="textSpecSpoiler"
                        defaultValue={textSpecSpoiler}
                        onChange={onChangeSpecSpoiler}
                      >
                        {Utility.getOptionsUnknownYesNo().map(
                          (state, stateIdx) => {
                            return (
                              <option key={stateIdx} value={state}>
                                {state}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="inputSpecTurinis"
                        className="tt-label-submit-form"
                      >
                        Speedline Turini Alloys
                      </label>
                      <select
                        className="form-control custom-select"
                        id="inputSpecTurinis"
                        name="textSpecTurinis"
                        defaultValue={textSpecTurinis}
                        onChange={onChangeSpecTurinis}
                      >
                        {Utility.getOptionsUnknownYesNo().map(
                          (state, stateIdx) => {
                            return (
                              <option key={stateIdx} value={state}>
                                {state}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12">
                  <label htmlFor="inputAddNotes" className="tt-label-submit-form">
                    Additional Notes
                  </label>
                  <textarea
                    rows="5"
                    className="form-control"
                    id="inputAddNotes"
                    placeholder="Additional notes..."
                    name="textAddNotes"
                    value={textAddNotes}
                    onChange={onChangeAddNotes}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <label className="text-white">
                    Do you have an image or two of your Trophy that you'd like to
                    share? If so, please provide image links in the 'Additional
                    Notes' section above.
                  </label>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12 mt-2">
                  <button type="submit" className="btn btn-success">
                    Send Information
                  </button>
                </div>
              </div>

              <div className="">
                <span className="text-warning">*</span> These fields are required.
              </div>
              <div className="">
                <span className="text-warning">*</span> Your e-mail address will
                not be used for any other reason than to reply to you directly (if
                there is a need to do so).
              </div>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={
                "alert alert-" + (svrReply.type === "ok" ? "success" : "warning")
              }
              role="alert"
            >
              <h3 className="alert-heading">{textName}</h3>
              <p>{svrReply.message}</p>
              {svrReply.type === "err" && (
                <button
                  className="btn btn-primary"
                  onClick={() => setSvrReply(null)}
                >
                  Try Again
                </button>
              )}
            </div>
          </Fragment>
        )}
        <BackToTop />
      </div>
    </div>
  );
};

export default Submit;
