import { Link } from 'react-router-dom';

import MiniHeroSection from "../containers/MiniHeroSection";

const NotFound = () => {
  return (
    <div className='mb-5'>
      <MiniHeroSection titleText="Not Found" sectionClassExtra="mb-3" iconName="fa-question-circle" />
      
      <div className="container">
        <p>Sorry, the page or item you are looking for could not be found.</p>
        <p>
          Return to the <Link to='/'>home</Link> page.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
