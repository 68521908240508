const BackToTop = () => {
  const onClickBackToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="btt-button">
      <div className="text-center">
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={onClickBackToTop}
          aria-label="back-to-top"
        >
          <div className="icon-wrapper">
            <i className="fas fa-chevron-up mr-5"></i>
          </div>
        </button>
      </div>
    </div>
  );
};

export default BackToTop;
