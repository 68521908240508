const TTForumInfoContainer = ({ trophyInfo }) => {
  return (
    <>
      {trophyInfo.forumThreadId && (
        <div className='mb-5'>
          <div className='text-muted'>
            More information may be available on the Trophy Owner
            Register (ClioTrophy.co.uk). You will need to be a
            registered member of to view the content (free to join).
            We are not affiliated with ClioTrophy.co.uk and claim no
            responsibility for the content found on their site.
          </div>
          <div className='text-white mt-3'>
            <i className='fas fa-search mr-2 text-secondary' />
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={
                'https://cliotrophy.co.uk/threads/' +
                trophyInfo.number.padStart(3, '0') +
                '.' +
                trophyInfo.forumThreadId +
                '/'
              }
            >
              Read more about Trophy #
              {trophyInfo.number.padStart(3, '0')}...
            </a>
          </div>
        </div>
      )}
    </>
  );
}
 
export default TTForumInfoContainer;