import { Link } from 'react-router-dom';
import MiniHeroSection from "../containers/MiniHeroSection";

const PrivacyPolicy = () => {
  return (
    <div className='mb-5'>
      <MiniHeroSection titleText="Privacy Policy" sectionClassExtra="mb-3" iconName="fa-exclamation-circle" />

      <div className="container">
        <p>
          The data held by TrophyTracker is deliberately sparse to avoid
          data protection issues and to maintain owner privacy. If you are
          the owner of a vehicle that is recorded on this site, and you
          wish to have the associated data removed, you have the right to
          contact us to request that the data be deleted from our records.
        </p>

        <h4 className='mt-4 text-white'>Data Accuracy</h4>
        <div className="mt-2">
          Due to the way in which TrophyTracker collects and records data [through
          the searching of freely-available information and/or through community
          contributions] TrophyTracker cannot guarantee, or be held responsible for,
          its accuracy.
        </div>

        <h4 className='mt-4 text-white'>External Links</h4>
        <div className="mt-2">
          TrophyTracker may contain links to external sites to provide you with
          additional information. Please be aware that TrophyTracker is not, and
          cannot, be held responsible for the content of those externally linked
          sites.
        </div>

        <h4 className='mt-4 text-white'>Acceptance of this Policy</h4>
        <div className="mt-2">
          Continued use of the TrophyTracker site signifies your acceptance of this
          policy. If you do not accept the policy then please do not use this site.
        </div>

        <h4 className='mt-4 text-white'>Changes to this Policy</h4>
        <div className="mt-2">
          We may make changes to this policy at any time. We encourage you to
          frequently check this page for any changes and to stay informed about how
          we are helping to protect the data we collect. You hereby acknowledge and
          agree that it is your responsibility to review this policy periodically.
        </div>

        <h4 className='mt-4 text-white'>Contacting Us</h4>
        <div className="mt-2">
          You can use the <Link to='/contact'>Contact page</Link> should you wish
          to get in touch regarding any questions or concerns you may have about
          the information used by TrophyTracker and/or how we handle data.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
