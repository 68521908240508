import { useState, useContext } from 'react';

import TrophysContext from '../../context/trophys/trophysContext';
import AlertContext from '../../context/alert/alertContext';

import { REQOP_BY_NUM, REQOP_BY_VRM, REQOP_BY_VIN } from '../../context/types';

const SearchTrophy = () => {
  const trophysContext = useContext(TrophysContext);
  const alertContext = useContext(AlertContext);

  const [textSearch, setTextSearch] = useState('');
  const [textReqOp, setTextReqOp] = useState(REQOP_BY_NUM);
  const [textType, setTextType] = useState('number');
  const [textPlaceholder, setTextPlaceholder] = useState('Number...');

  const doSearchByNum = () => {
    // Trim leading/trailing whitespace and leading zeros
    let valStr = textSearch.trim();
    valStr = valStr.replace(/^0+/, '');

    // Check for a valid number
    const regExp = new RegExp('^\\d+$');
    if (valStr.length > 3 || !regExp.test(valStr)) {
      alertContext.setAlert('Please enter a valid number!', 'danger');
      return;
    }

    // Check the integer value to ensure it is in the correct range
    const valInt = parseInt(valStr);
    if (valInt > 0 && valInt < 501) {
      trophysContext.searchTrophy(REQOP_BY_NUM, valStr);
    } else {
      alertContext.setAlert(
        'Please enter a number in the range of 1-500.',
        'danger'
      );
    }
  };

  const doSearchByVRM = () => {
    // Trim and remove whitespace, convert to uppercase
    let valStr = textSearch.trim();
    valStr = valStr.replace(/\s/g, '').toUpperCase();

    // Check for a valid registration - https://gist.github.com/danielrbradley/7567269
    const regExp = new RegExp(
      '(^[A-Z]{2}[0-9]{2}s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)'
    );
    if (!regExp.test(valStr)) {
      alertContext.setAlert('Please enter a valid registration!', 'danger');
    } else {
      trophysContext.searchTrophy(REQOP_BY_VRM, valStr);
    }
  };

  const doSearchByVIN = () => {
    // Trim and remove whitespace, convert to uppercase
    let valStr = textSearch.trim();
    valStr = valStr.replace(/\s/g, '').toUpperCase();

    // Check for a valid VIN - Unable to derive a suitable regex for UK VIN at present...
    const regExp = new RegExp('[VF1CB220F34]{11}[0-9]{6}');
    if (valStr.length !== 17 || !regExp.test(valStr)) {
      alertContext.setAlert('Please enter a valid VIN!', 'danger');
    } else {
      trophysContext.searchTrophy(REQOP_BY_VIN, valStr);
    }
  };

  const onSubmit = evt => {
    evt.preventDefault();

    // Determine the search type to be carried out
    if (textReqOp === REQOP_BY_NUM) {
      // Search by unique number
      doSearchByNum();
    } else if (textReqOp === REQOP_BY_VRM) {
      // Search by registration plate
      doSearchByVRM();
    } else if (textReqOp === REQOP_BY_VIN) {
      // Search by VIN
      doSearchByVIN();
    } else {
      // Something went wrong...
      alertContext.setAlert(
        'Sorry, an error has occurred. Please try again.',
        'danger'
      );
    }
  };

  const onClearResults = evt => {
    evt.preventDefault();
    setTextSearch('');
    trophysContext.clearTrophy();
  };

  const onChangeSearch = evt => {
    setTextSearch(evt.target.value);
  };

  const onClickSearchType = evt => {
    setTextSearch('');
    setTextReqOp(evt.target.dataset.searchtype);

    switch (evt.target.dataset.searchtype) {
      case REQOP_BY_NUM:
      default:
        setTextType('number');
        setTextPlaceholder('Number...');
        break;
      case REQOP_BY_VRM:
        setTextType('text');
        setTextPlaceholder('Registration...');
        break;
      case REQOP_BY_VIN:
        setTextType('text');
        setTextPlaceholder('VIN...');
        break;
    }
  };

  return (
    <div>
      <div className='mb-2'>I want to search by...</div>
      <div>
        <div
          className='btn-group btn-group-toggle d-flex mb-3'
          data-toggle='buttons'
          role='group'
        >
          <label className='btn btn-secondary w-100 active'>
            <input
              type='radio'
              name='options'
              id='option1'
              data-searchtype={REQOP_BY_NUM}
              onClick={onClickSearchType}
              defaultChecked
            />{' '}
            Number
          </label>
          <label className='btn btn-secondary w-100'>
            <input
              type='radio'
              name='options'
              id='option2'
              data-searchtype={REQOP_BY_VRM}
              onClick={onClickSearchType}
            />{' '}
            Registration
          </label>
          <label className='btn btn-secondary w-100'>
            <input
              type='radio'
              name='options'
              id='option3'
              data-searchtype={REQOP_BY_VIN}
              onClick={onClickSearchType}
            />{' '}
            VIN
          </label>
        </div>

        <form onSubmit={onSubmit}>
          <div className='form-row'>
            <div className='col-md-4'>
              <div className='input-group'>
                <input
                  className='form-control'
                  type={textType}
                  name='textSearch'
                  placeholder={textPlaceholder}
                  value={textSearch}
                  onChange={onChangeSearch}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='col-md-4 mt-md-0 mt-2'>
              <input
                type='submit'
                value='Search Now'
                className='btn btn-success btn-block'
              />
            </div>
            <div className='col-md-4 mt-md-0 mt-2'>
              {trophysContext.trophy && (
                <button
                  className='btn btn-warning btn-block'
                  onClick={onClearResults}
                >
                  Clear Results
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchTrophy;
