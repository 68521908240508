import { useContext } from 'react';

import TrophysContext from '../../context/trophys/trophysContext';

const SearchMetrics = () => {
  const trophysContext = useContext(TrophysContext);

  return (
    <form>
      <div className='form-row'>
        <div className='col-md-4'>
          <button
            type='button'
            className='btn btn-success btn-block'
            onClick={trophysContext.searchMetrics}
          >
            Refresh Statistics
          </button>
        </div>

        <div className='col-md-4 mt-md-0 mt-2'>
          {trophysContext.metrics && (
            <button
              type='button'
              className='btn btn-warning btn-block'
              onClick={trophysContext.clearMetrics}
            >
              Clear Statistics
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default SearchMetrics;
