import ResponsiveYouTubeEmbed from "../containers/ResponsiveYouTubeEmbed";
import MiniHeroSection from "../containers/MiniHeroSection";
import LazyLoad from "react-lazyload";

import BackToTop from '../layout/BackToTop';

import imgIconicSide01 from "../../images/iconic-side-01.jpg";
import imgIconicSide02 from "../../images/iconic-side-02.jpg";
import imgIconicSide03 from "../../images/iconic-side-03.jpg";
import imgIconicSide04 from "../../images/iconic-side-04.jpg";
import imgIconicSide05 from "../../images/iconic-side-05.jpg";
import imgIconicSide06 from "../../images/iconic-side-06.jpg";

const IconicHotHatch = () => {
  return (
    <div className="mb-5">
      <MiniHeroSection titleText="The Iconic Hot-Hatch" sectionClassExtra="mb-5" iconName="fa-trophy" />

      <section className="pb-5">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="text-white mb-0 pb-2">But It's a Clio!</h3>
              <div>
                Ok, so it's a Clio. We get that. And we understand that the majority
                of people who gaze over the Renaultsport Clio 182 Trophy's Capsicum
                Red paintwork will not even give it a second look. After all, it's
                just another reasonably sporty-looking Clio much like any other that
                wears the Renaultsport 172 or 182 title, right? So why is it held in
                such high regard and why is it described as a modern day classic?
              </div>
              <div className="mt-3">
                To judge the Trophy on looks alone is to do it a disservice. The
                real beauty of this car, and the potential enjoyment it can deliver,
                lies deep beneath the red paintwork. It's in the way it drives and
                the way it handles, the 'chuckability' and the sheer fun you can have
                whilst hustling along your favourite B-roads. It's these very reasons
                why the Trophy is held in such high regard and why this little French
                hot-hatch is so highly sought after.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide02} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary py-5">
        <div className="container position-relative">
          <h3 className="pb-2 text-white">The Expert Opinion</h3>
          <div className="row">
            <div className="col-12">
              But is it really that good? Well, maybe the views of a certain
              Harry Metcalfe may persuade you. If you're reading this then it is
              highly likely that you already know who Harry is. For those who don't,
              Harry formed and ran a specialist motoring magazine that many will know as
              EVO (suitably taglined with, "The Thrill of Driving"). Whilst Harry no
              longer runs EVO, he is still very much involved in the specialist motoring
              world and has an extremely impressive car and motorbike collection of his
              own. Nestled among the rare and expensive machinery sits a Capsicum Red
              coloured Clio. In the following video, Harry tells us more about his
              fascination for the Renaultsport Clio 182 Trophy.
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <ResponsiveYouTubeEmbed videoId="BTTI-aMKn60" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              Still not convinced? Then let's take a closer look at what makes this
              special little car tick.
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Any Colour, As Long As It's Red</h3>
              <div>
                Whilst similar in looks to other RenaultSport Clios of its age, the Trophy
                is very much a limited edition model. Only 550 models were produced - 500
                for the UK and 50 for Switzerland - all of them wore the Capsicum Red paintwork.
                Had Renault France had more faith in the 182 Trophy then there may well have
                been a fair few more rolling off of the production line. As it turned out,
                Renault France decided that there wasn't a sufficient market for the 182 Trophy
                hence decided against producing models for release within their own territory.
                Whilst that decision may have been a mistake on the part of Renault's marketing
                department it was a decision that Trophy owners no doubt welcomed, helping to
                maintain exclusivity and the feeling that they actually had something special
                parked up in the garage or on the driveway.
              </div>
              <div className="mt-3">
                In addition to the red paintwork (designation '727') the sideskirts were
                adorned with a lacquered "Trophy" decal (should you ever wonder what it was you
                were looking at). However, the differences didn't end there and a few choice
                additions really helped make the car stand out from the pack.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide03} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Trick Dampers?</h3>
              <div>
                Perhaps the most notable of all the additions are the 'trick' Sachs Race
                Engineering dampers. These dampers have a remote reservoir and allow a stiffer
                and thicker damper rod to be used. But such desirable items do come with
                associated costs. There will come a time when the dampers need rebuilding and,
                currently, there are only a few places that can offer the service. It pays to
                look around come rebuild time as there may be better options available thanks
                to the generous nature, and engineering expertise, of existing owners within
                the Trophy-ownership community. Of course, you could always buy brand new but -
                at over £3,000 for the pair - it's not always an option! In fact, it is no longer
                an option as these sought-after Sachs dampers are no longer available!
              </div>
              <div className="mt-3">
                Now and again a set will appear for sale online but are usually snapped up
                quite quickly. Secondhand sets often require a rebuild which can cost anywhere from
                £500 to £1,400 (depending on who does the work and the work / parts required).
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide05} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Are You Sitting Comfortably?</h3>
              <div>
                Whilst the 182 Trophy is never going to win any awards for creature comforts or
                interior luxury it does come equipped, as standard, with Recaro Trendline
                seats. On the base of the seats is positioned a plaque that proudly shows the
                unique number of the vehicle. Interestingly, this is also the only known way by
                which a Trophy can be identified (hence a big part of the reason as to why this
                website was created). A minor issue with the seats is that they are, perhaps, a
                little too high and they can leave the driver sat in too high a driving position
                when behind the wheel.
              </div>
              <div className="mt-3">
                It is known that some 182 Trophys no longer have their original Recaro Trendline
                seats in them as they have been sold on for one reason or another. However, as is
                the case with the Sachs dampers, the Recaro Trendline seats are highly-desirable
                items that help set the Trophy apart from the rest of the Renaultsport Clio Mk2
                range. As a result, most owners choose to retain the Recaro Trendlines to ensure
                originality and higher residual values (or try to source a set should their own
                vehicle not have them).
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide04} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Alloy There, Spoiler Alert!</h3>
              <div>
                The other additions to the Renaultsport Clio 182 Trophy are perhaps more subtle.
                The Trophy Speedline Turini alloy wheels save approximately 1.3kg per corner and
                have a slightly different offset to those found on the Clio 172 Cup. The front hubs
                are also uprated items and the front suspension sits 10mm lower, too.
              </div>
              <div className="mt-3">
                Looking higher and towards the rear, the Clio 182 Trophy wears a spoiler that can
                also be seen on the bonkers Clio V6 255. It providea a nice and subtle finish to the
                overall look of the car but does come with a slight drawback. Be prepared for
                cursing and a sore head as the spoiler actually restricts the distance that the boot
                can open! The tailgate typically hovers around the height where your forehead is
                positioned as you lean in towards the boot space... You have been warned!
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide01} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Winding It Up</h3>
              <div>
                A quick look at the engine specs will tell you that it's not the fastest hot-hatch
                out there, especially not by modern-day standards. But to simply be fast is to miss
                the whole point of this car. It's about what the car can do and how it feels as a
                complete package. It's not that slow if you are concerned with how quickly you can
                get from A to B; the 1998cc, 4-cylinder engine - with an output of 182ps and 147lbft
                of torque - does provide that 'small car, big engine' feel, especially considering
                the the car only weighs in at a touch over 1,000kg's.
              </div>
              <div className="mt-3">
                As owners and enthusiasts of the Clio 182 Trophy we could write paragraphs stating how
                great the car is. We would implore you to try and grab some time behind the wheel of
                a 182 Trophy. It's fun, it's addictive and we're pretty certain you'd be able to
                understand why so many specialist motoring publications and journalists rate the humble
                182 Trophy as one of the best hot-hatches ever produced.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <LazyLoad offset={100} once>
                <img className="side-image-rounded" src={imgIconicSide06} alt="" title="" />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
      <BackToTop />
    </div>
  );
};

export default IconicHotHatch;
