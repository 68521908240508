import Utility from '../util/Utility';

const TTGeneralContainer = ({ trophyInfo }) => {
  return (
    <>
      <h3 className='tt-heading text-warning'>General</h3>
      <div className='mb-0'>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className='text-muted'>Date of Registration</div>
            <div className='text-white'>
              {trophyInfo.registrationDate
                ? new Date(trophyInfo.registrationDate).toDateString()
                : '-'}
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className='text-muted'>Last Known Location</div>
            <div className='text-white'>
              {trophyInfo.area}, {trophyInfo.country}
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className='text-muted'>
              Vehicle Identification Number (VIN)
            </div>
            <div className='text-white'>
              {trophyInfo.vehicleIdNum
                ? trophyInfo.vehicleIdNum
                : '-'}
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className='text-muted'>Original VRM</div>
            <div className='text-white'>
              {trophyInfo.originalReg ? trophyInfo.originalReg : '-'}
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className='text-muted'>Last Known VRM</div>
            <div className='text-white'>
              {trophyInfo.lastKnownReg
                ? trophyInfo.lastKnownReg
                : '-'}
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
            <div className='text-muted'>Last Known Mileage</div>
            <div className='text-white'>
              {trophyInfo.lastKnownMileage
                ? Utility.numWithCommas(trophyInfo.lastKnownMileage)
                : '-'}
              {trophyInfo.dateLastKnownMileage && (
                <span className='text-info'>
                  {' '}
                  <small>
                    (
                    {new Date(
                      trophyInfo.dateLastKnownMileage
                    ).toLocaleDateString('en-GB')}{' '}
                    -{' '}
                    {Utility.dateDiffInDaysString(trophyInfo.dateLastKnownMileage, new Date())})
                  </small>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default TTGeneralContainer;