const ChangeLog = [
  {
    version: "v1.8.2",
    commit: "",
    log: (
      <ul>
        <li>
          On Stats page, added buyer/seller listings by year (toggle button to show/hide listings).
        </li>
      </ul>
    ),
  },
  {
    version: "v1.8.1",
    commit: "",
    log: (
      <ul>
        <li>
          Removed Parts Check feature - too difficult to maintain without automation, little-used feature.
        </li>
        <li>
          Removed News and Events feature - this may return in a future version.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.8.0",
    commit: "",
    log: (
      <ul>
        <li>
          Updated Stats page to show additional data that allows the viewer to distinguish
          between seller (sale) prices and buyer (purchase) prices.
        </li>
        <li>
          Bug fixes to address incorrect display of data on Stats page.
        </li>
        <li>
          Updated styling to accommodate new features and associated changes.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.5",
    commit: "",
    log: (
      <ul>
        <li>
          Expanded MOT section with mileage chart, odometer deltas and average
          mileage overview.
        </li>
        <li>
          Updated charts to (hopefully) be more mobile-friendly - added support
          for pinch/zoom and pan. PC/laptop users can also benefit from improved
          chart navigation through the use of their mouse.
        </li>
        <li>
          Minor bug fixes for when displaying MOT data for vehicles that have not
          yet had their first test.
        </li>
        <li>
          Updated styling to improve UX for mobile users.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.4",
    commit: "",
    log: (
      <ul>
        <li>
          Added a page for the Trophy Welsh Weekender to provide more information
          about this annual event.
        </li>
        <li>
          Added a new Hero Section component.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.3",
    commit: "",
    log: (
      <ul>
        <li>
          Bugfix to reinstate automatic retrieval of stats data the first time
          the stats page is visited per session.
        </li>
        <li>
          Removed 'Disclaimer' page and replaced it with a new 'Privacy Policy'
          page.
        </li>
        <li>
          Added icons to Search, Location, Submit and Contact content buttons on
          Home page.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.2",
    commit: "",
    log: (
      <ul>
        <li>
          Added Iconic Hot-Hatch page to describe what makes the Clio 182 Trophy
          such a special car.
        </li>
        <li>
          Further changes to home page (and others) to use improved styling and
          layout. This will continue to evolve over coming releases.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.1",
    commit: "",
    log: (
      <ul>
        <li>
          The list of data items stored, as shown in the Contribute screen, have
          been reorganised to better fill the available screen width.
        </li>
        <li>
          A new Meet the Team section has been added to provide more information
          of the people behind the site.
        </li>
        <li>
          Updated the Location screen so the retrieval of data is performed
          automatically the first time the page is visited per session.
        </li>
        <li>
          Added a new BiogCard component for use in the Meet The Team screen.
        </li>
        <li>
          Updated the Stats screen so the retrieval of data is performed
          automatically the first time the page is visited per session.
        </li>
        <li>
          Relocated lower priority navigation links from the header to the
          footer.
        </li>
        <li>
          Address minor accessibility issues highlighted by the Google
          Lighthouse report.
        </li>
        <li>Revised Home page.</li>
        <li>News and Events screen and data list added.</li>
        <li>
          Added a Parts Check page so that users can check the availability of
          parts by searching against part names and/or part numbers.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.7.0",
    commit: "",
    log: (
      <ul>
        <li>
          Introduced using a SCSS file to manage and generate CSS from within
          the IDE.
        </li>
        <li>Revised the footer styling to apply CSS sticky positioning.</li>
        <li>Replaced an ID (used for styling) with a CSS class (className.)</li>
        <li>Separated out the SCSS file into individual partials.</li>
        <li>Moved SCSS and CSS files into a 'styles' folder</li>
        <li>
          Created a Change Log array containing details from the ChangeList
          screen
        </li>
        <ul>
          <li>
            Utilised the Change Log array to present the same 'latest edition'
            version number in the footer component.
          </li>
          <li>
            Revised the ChangeList screen to present details from the Change Log
            array. Also extended the entries to include (where possible) links
            to the GitHub commit.
          </li>
        </ul>
      </ul>
    ),
  },
  {
    version: "v1.6.6",
    commit: "",
    log: (
      <ul>
        <li>
          Additional stats / metrics added to the Stats section of the
          application.
        </li>
        <li>Minor UI tweaks / text changes to improve user experience.</li>
        <li>
          Moved over to use the JS Internationalisation API (all based on
          'en-gb' locale for now).
        </li>
        <li>Backend improvements for retrieving data.</li>
      </ul>
    ),
  },
  {
    version: "v1.6.5",
    commit: "",
    log: (
      <ul>
        <li>
          Made the decision to include a general MOT Check page (you can only
          get results for tests done in England, Scotland or Wales since 2005).
        </li>
      </ul>
    ),
  },
  {
    version: "v1.6.4",
    commit: "",
    log: (
      <ul>
        <li>
          Improved MOT information display (now shows expiry or expired date in
          MOT History header bar area).
        </li>
        <li>Minor styling changes to improve UX across more devices.</li>
      </ul>
    ),
  },
  {
    version: "v1.6.3",
    commit: "",
    log: (
      <ul>
        <li>
          Added extra information to timeline events for vehicle mileage and
          value (if available).
        </li>
        <li>
          A few under-the-hood tweaks to the backend to improve database access
          performance.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.6.2",
    commit: "",
    log: (
      <ul>
        <li>
          Added 'copy to clipboard' button to grab the URL for the currently
          displayed vehicle.
        </li>
        <li>
          Enabled searching via unique Trophy number (using endpoint URL).
        </li>
        <ul>
          <li>
            e.g.{" "}
            <strong>
              {process.env.REACT_APP_TTHOST}search/num/&lt;
              <span className="text-warning">number</span>&gt;
            </strong>
          </li>
          <li>
            Where &lt;<span className="text-warning">number</span>&gt; can be
            1-500 inclusive.
          </li>
        </ul>
        <li>
          Enabled searching via vehicle's VRM (registration plate) (using
          endpoint URL).
        </li>
        <ul>
          <li>
            e.g.{" "}
            <strong>
              {process.env.REACT_APP_TTHOST}search/vrm/&lt;
              <span className="text-warning">vrm</span>&gt;
            </strong>
          </li>
          <li>
            Where &lt;<span className="text-warning">vrm</span>&gt; is the
            registration (without spaces).
          </li>
        </ul>
        <li>Enabled searching via vehicle's VIN (using endpoint URL).</li>
        <ul>
          <li>
            e.g.{" "}
            <strong>
              {process.env.REACT_APP_TTHOST}search/vin/&lt;
              <span className="text-warning">vin</span>&gt;
            </strong>
          </li>
          <li>
            Where &lt;<span className="text-warning">vin</span>&gt; is the
            Vehicle Identification Number (without spaces).
          </li>
        </ul>
      </ul>
    ),
  },
  {
    version: "v1.6.1",
    commit: "",
    log: (
      <ul>
        <li>Renamed the 'Find' page to 'Search'.</li>
        <li>Renamed the 'Metrics' page to 'Stats'.</li>
        <li>
          Removed MOT information from Timeline data (no longer needed following
          the introduction of the MOT History section).
        </li>
        <li>
          Refactored the Timeline section on the Search page as it sadly had
          issues on some devices and browsers.
        </li>
        <li>
          Updated the Home page with direct button links to Search, Location,
          Submit and Contact pages.
        </li>
        <li>Updated the Stats page.</li>
        <li>Reordered position of items on the Navbar.</li>
      </ul>
    ),
  },
  {
    version: "v1.6.0",
    commit: "",
    log: (
      <ul>
        <li>
          Added support for requesting MOT history data on demand (directly from
          the DVSA).
        </li>
        <li>Minor UI changes and fixes.</li>
      </ul>
    ),
  },
  {
    version: "v1.5.2",
    commit: "",
    log: (
      <ul>
        <li>General bugfixes to layout issues and minor UI improvements.</li>
        <li>Added copyright message to Images section on the Find page.</li>
        <li>Updated how images are handled and loaded (lazily).</li>
      </ul>
    ),
  },
  {
    version: "v1.5.1",
    commit: "",
    log: (
      <ul>
        <li>Updated to fix a filtering issue with the Timeline feature.</li>
      </ul>
    ),
  },
  {
    version: "v1.5.0",
    commit: "",
    log: (
      <ul>
        <li>
          'Under-the-hood' refactoring and improvements to allow easier
          implementation of future updates, improvements and new features.
        </li>
        <li>
          New 'timeline' feature that lists key events in a vehicle's history
          (if data is available).
        </li>
      </ul>
    ),
  },
  {
    version: "v1.4.0",
    commit: "",
    log: (
      <ul>
        <li>Updated to use newer supporting libs and packages.</li>
        <li>Minor bugfixes and typo corrections.</li>
      </ul>
    ),
  },
  {
    version: "v1.3.5",
    commit: "",
    log: (
      <ul>
        <li>
          Minor changes to Location page to show all known Trophys in numerical
          order (including scrapped vehicles) before displaying by country and
          area.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.3.4",
    commit: "",
    log: (
      <ul>
        <li>Added support for Trophy car images (if provided).</li>
        <li>Improved support for displaying Additional Notes on Find page.</li>
        <li>Minor styling tweaks / fixes on Find page.</li>
      </ul>
    ),
  },
  {
    version: "v1.3.3",
    commit: "",
    log: (
      <ul>
        <li>Addition of some basic metrics based upon data held.</li>
        <li>Minor styling tweaks / fixes.</li>
      </ul>
    ),
  },
  {
    version: "v1.3.2",
    commit: "",
    log: (
      <ul>
        <li>Styling changes</li>
      </ul>
    ),
  },
  {
    version: "v1.3.1",
    commit: "",
    log: (
      <ul>
        <li>
          Added support for searching by Vehicle Identification Number (VIN) on
          the Find page.
        </li>
        <li>
          Reworked the UI on the Find page to provide a better user experience.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.3.0",
    commit: "",
    log: (
      <ul>
        <li>
          Added support for searching by Vehicle Registration Marker
          (registration plate) on the Find page.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.2.0",
    commit: "",
    log: (
      <ul>
        <li>
          Added new Submit page that allows the user to provide (limited)
          information for a specific Trophy.
        </li>
        <li>
          Updated Contribute page and Contact page with links to new Submit
          page.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.1.1",
    commit: "",
    log: (
      <ul>
        <li>Added note to Contact page regarding usage of email address.</li>
        <li>
          Contact form now routes messages to a bespoke 'Trophy Tracker' email
          address.
        </li>
      </ul>
    ),
  },
  {
    version: "v1.1.0",
    commit: "",
    log: (
      <ul>
        <li>Added support for 17 character VIN.</li>
        <li>Refactored fixed footer (made into a sticky footer).</li>
        <li>Added 'Back To Top' option on pages where it is required.</li>
        <li>
          Moved 'Disclaimer' to a separate page (rather than appearing as a
          panel at the bottom of every page).
        </li>
      </ul>
    ),
  },
  {
    version: "v1.0",
    commit: "",
    log: (
      <ul>
        <li>Initial release.</li>
      </ul>
    ),
  },
];

export default ChangeLog;
