import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Alert from '../layout/Alert';
import ServerError from '../layout/ServerError';
import SearchTrophy from '../trophys/SearchTrophy';
import Spinner from '../layout/Spinner';
import BackToTop from '../layout/BackToTop';
import Utility from '../util/Utility';

import TTGeneralContainer from '../containers/TTGeneralContainer';
import TTSignatureItemsContainer from '../containers/TTSignatureItemsContainer';
import TTAdditionalNotesContainer from '../containers/TTAdditionalNotesContainer';
import TTForumInfoContainer from '../containers/TTForumInfoContainer';
import TTMOTContainer from '../containers/TTMOTContainer';
import TTTimelineContainer from '../containers/TTTimelineContainer';
import TTImagesContainer from '../containers/TTImagesContainer';
import MiniHeroSection from "../containers/MiniHeroSection";

import TrophysContext from '../../context/trophys/trophysContext';

import { REQOP_BY_NUM, REQOP_BY_VRM, REQOP_BY_VIN } from '../../context/types';

const Search = () => {
  const trophysContext = useContext(TrophysContext);
  const { loading, trophy } = trophysContext;
  const history = useHistory();
  const { num, vrm, vin } = useParams();
  const [textCopied, setTextCopied] = useState("");

  useEffect(() => {
    // Checks for routing param - allows automatic search via URL endpoints
    if (num !== undefined) {
      trophysContext.searchTrophy(REQOP_BY_NUM, num);
    } else if (vrm !== undefined) {
      trophysContext.searchTrophy(REQOP_BY_VRM, vrm);
    } else if (vin !== undefined) {
      trophysContext.searchTrophy(REQOP_BY_VIN, vin);
    }

    if ((num !== undefined) || (vrm !== undefined) || (vin !== undefined)) {
      history.push('/search');
    }
  }, [num, vrm, vin, history, trophysContext]);

  const onClickCopy = (urlEndpoint) => {
    // Confirm copy to clipboard action
    setTextCopied("URL copied to clipboard!");

    setTimeout(() => {
      // Remove copy to clipboard confirmation
      setTextCopied("");
    }, 2500);
  };

  return (
    <div className='mb-5'>
      <MiniHeroSection titleText="Search" sectionClassExtra="mb-3" iconName="fa-search" />

      <div className="container">
        <p>
          Search for a specific Trophy using its unique number, registration plate or VIN.
        </p>

        <Alert />
        <ServerError />
        <SearchTrophy />

        {textCopied.length > 0 && (
          <div className="simple-toast">{textCopied}</div>
        )}

        {loading && <Spinner />}

        {trophy && (
          <Fragment>
            {!trophy.found ? (
              <div className='card border-warning mt-4 text-white'>
                <div className='card-body'>
                  <h5 className='card-title'>{trophy.info}</h5>
                  <p className='card-text'>
                    Please try searching using a different search term.
                  </p>
                </div>
              </div>
            ) : (
              <div className='card border-black mt-4 text-white'>
                <div className='card-header d-flex flex-row justify-content-between align-items-center'>
                  <div>
                    <h3 className='m-0'>
                      Trophy #{trophy.info.number.padStart(3, '0')}
                    </h3>
                    <small>({Utility.getPluralisedWithValueIfNeeded('view', trophy.info.viewHits)})</small>
                  </div>
                  <div>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_TTHOST}search/num/${trophy.info.number}`}
                      onCopy={() => onClickCopy(`${process.env.REACT_APP_TTHOST}search/num/${trophy.info.number}`)}
                    >
                      <button type='button' className='btn btn-secondary py-2'><i className="fas fa-clipboard fa-2x fa-fw text-white" title='Copy URL to Clipboard' /></button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='card-text'>

                    <h3 className='tt-heading text-warning'>Important</h3>
                    <div className='mb-5'>
                      <div>
                        This record was last updated on{' '}
                        {new Date(trophy.info.lastUpdated).toLocaleDateString(
                          'en-GB'
                        )}{' '}
                        ({Utility.dateDiffInDaysString(
                          trophy.info.lastUpdated,
                          new Date()
                        )}). Data presented cannot be guaranteed accurate as to the vehicle's current state.
                      </div>

                      {/* Has the vehicle been scrapped? */}
                      {trophy.info.isScrapped && (
                        <div className='alert alert-danger mt-3' role='alert'>
                          <i className='fas fa-exclamation-triangle mr-2' />
                          <strong>This vehicle has been scrapped.</strong>
                        </div>
                      )}                  
                    </div>

                    <TTGeneralContainer trophyInfo={trophy.info} />
                    <TTSignatureItemsContainer trophyInfo={trophy.info} />
                    <TTAdditionalNotesContainer trophyInfo={trophy.info} />
                    <TTForumInfoContainer trophyInfo={trophy.info} />
                    <TTMOTContainer trophyInfo={trophy.info} />
                    <TTTimelineContainer trophyInfo={trophy.info} />
                    <TTImagesContainer trophyInfo={trophy.info} />
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
        <BackToTop />
      </div>
    </div>
  );
};

export default Search;
