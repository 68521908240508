import { Link } from "react-router-dom";

import MiniHeroSection from "../containers/MiniHeroSection";

const Contribute = () => {
  return (
    <div className="mb-5">
      <MiniHeroSection titleText="Contribute" sectionClassExtra="mb-3" iconName="fa-info-circle" />

      <div className="container">
        <p>
          We welcome contributions from existing owners, or enthusiasts, that can
          help towards keeping the data on this site fresh and up-to-date. Perhaps
          you've recently bought a Trophy and it now resides in a different
          county, or maybe even a different country? Maybe you've just returned
          from an epic pan-Europe road trip and added several thousand fun-filled
          miles to the odometer?
        </p>
        <p>
          It is important that data remains fairly sparse and cannot be used to
          directly identify owners and/or individuals. Hence, the data items we
          present (if known) are limited to:
        </p>

        <ul className="data-items-list">
          <li>Trophy Number</li>
          <li>Date of Registration</li>
          <li>County (or Principal Area)</li>
          <li>Country</li>
          <li>Vehicle Identification Number (VIN)</li>
          <li>Original Vehicle Registration Mark (VRM)</li>
          <li>Last Known VRM</li>
          <li>Last Known Mileage</li>
          <li>Date of Last Known Mileage</li>
          <li>Additional Notes</li>
          <li>Has it been scrapped?</li>
          <li>Sachs Dampers present?</li>
          <li>Recaro Trendlines present?</li>
          <li>V6 Spoiler present?</li>
          <li>Trophy Turinis present?</li>
          <li>Link to Owner Register on ClioTrophy.co.uk</li>
        </ul>

        <p>
          If you would like to provide information about a specific Trophy then
          please use the form on the <Link to="/submit">Submit page</Link>. We may
          ask for some form of basic verification before any changes are made to
          the data we hold. For any other enquiries, please use the{" "}
          <Link to="/contact">Contact page</Link> to reach us.
        </p>

        <p>
          We may decide to expand on this in the future but have no plans to do so
          at present. As this is a 'free-time' and non-commercial project there
          isn't necessarily time available to grow the site, other than updating
          the existing data as and when possible. However, any significant changes
          that are made can be seen in the{" "}
          <Link to="/changelist">Changelist</Link>.
        </p>

        <div className="mb-5">
          Please be aware that we do not have any affiliation with the{" "}
          <a href="https://ClioTrophy.co.uk">ClioTrophy.co.uk</a> website and
          forums and are not responsible for the content found on their site.
          However, we would recommend the website as a useful Trophy resource for
          owners and enthusiasts alike.
        </div>
      </div>
    </div>
  );
};

export default Contribute;
