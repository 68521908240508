import { Fragment, useContext } from 'react';
import TrophyContext from '../../context/trophys/trophysContext';

const ServerError = () => {
  const trophysContext = useContext(TrophyContext);

  return (
    <Fragment>
      {trophysContext.serverError && (
        <div className={`alert alert-${trophysContext.serverError.type}`}>
          <i className='fas fa-exclamation-circle mr-2' />
          {trophysContext.serverError.msg}
        </div>
      )}
    </Fragment>
  );
};

export default ServerError;
