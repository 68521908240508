import { useContext } from 'react';

import TrophysContext from '../../context/trophys/trophysContext';

const SearchLocation = () => {
  const trophysContext = useContext(TrophysContext);

  return (
    <form>
      <div className='form-row'>
        <div className='col-md-4'>
          <button
            type='button'
            className='btn btn-success btn-block'
            onClick={trophysContext.searchAllData}
          >
            Refresh List
          </button>
        </div>

        <div className='col-md-4 mt-md-0 mt-2'>
          {trophysContext.allData.locAll && trophysContext.allData.locAll.length > 0 && (
            <button type='button' className='btn btn-warning btn-block' onClick={trophysContext.clearAllData}>
              Clear List
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default SearchLocation;

/*

        <div className='col-md-4 mt-md-0 mt-2'>
          {trophysContext.allData.byLocation &&
            trophysContext.allData.unsorted &&
            trophysContext.allData.byLocation.length > 0 &&
            trophysContext.allData.unsorted.length > 0 && (
              <button
                type='button'
                className='btn btn-warning btn-block'
                onClick={trophysContext.clearAllData}
              >
                Clear List
              </button>
            )}
        </div>

*/