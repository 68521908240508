import React, { useRef } from 'react';

import ResponsiveYouTubeEmbed from "../containers/ResponsiveYouTubeEmbed";
import HeroSection from "../containers/HeroSection";
import CaptionedImageContainer from "../containers/CaptionedImageContainer";
import BackToTop from '../layout/BackToTop';

import imgHeroLogo from "../pages/weekender-logo-1000x165px.png";
import imgHeroOverlay from "../pages/weekender-overlay-425x250.png";

import imgWeekender01 from "../../images/weekender-side-01.jpg";
import imgWeekender02 from "../../images/weekender-side-02.jpg";
import imgWeekender03 from "../../images/weekender-side-03.jpg";
import imgWeekender04 from "../../images/weekender-side-04.jpg";
import imgWeekender05 from "../../images/weekender-side-05.jpg";
import imgWeekender06 from "../../images/weekender-side-06.jpg";
import imgWeekender07 from "../../images/weekender-side-07.jpg";

const WelshWeekender = () => {
  const articleRef = useRef();

  return (
    <div className="mb-5">
      <HeroSection sectionClassExtra="mb-5" logo={imgHeroLogo} overlay={imgHeroOverlay} />

      <section className="pb-5">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="text-white mb-0 pb-2">What is the TWW?</h3>
              <div>
                The Trophy Welsh Weekender (TWW) is an annual event that sees many owners,
                and their family members and/or friends, joining together to enjoy a rather
                special road trip. Starting early on the Saturday morning, attendees embark
                on a drive that will take them from north to south - along some of Wales' finest
                driving roads and through some absolutely incredible Welsh scenery. A blend of
                challenging roads, against a backdrop that only Wales can provide, provides
                plenty of rewards for drivers and passengers alike.
              </div>
              <div className="mt-3">
                The TWW typically takes place mid-September each year and has been running
                since 2015. Sadly, the Coronoavirus pandemic meant that the 2020 TWW had to be
                cancelled, yet it returned as strong and successful as ever in 2021! The hope
                is that this event will continue to run for many more years to come.
              </div>
              <div className="mt-3">
                The popularity of the event drew the interest of a popular French performance
                car magazine back in 2017. I was asked to contribute a narrative and photos
                for a feature that would be published in a future edition of the magazine. I
                have included the narrative, along with a selection of photos, below.
              </div>
              <div className="mt-3">
                <button type="button" className="btn btn-primary" onClick={() => {
                  articleRef.current.scrollIntoView({ behavior: 'smooth' });
                }}>
                  Read Article
                </button>
              </div>
              <div className="mt-3">
                - Andy
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender01} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary py-5">
        <div className="container position-relative">
          <h3 className="pb-2 text-white">Video Teaser</h3>
          <div className="row">
            <div className="col-12">
              Here's a short video that may help provide more of a flavour as to what the
              Trophy Welsh Weekender is all about.
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <ResponsiveYouTubeEmbed videoId="VfTqfa5_jlA" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              Interested now? Then please read on...
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5" ref={articleRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 className="pb-2 text-white">Published Article</h3>
              <hr className="bg-secondary" />
              <div className="text-muted">
                As the author and photographer, I present here a TWW article that was published
                in Performance French Car magazine in 2017.
              </div>
              <div className="text-muted mt-2">
                - Andy Eder
              </div>
              <hr className="bg-secondary" />
              <div className="mt-3">
                The RenaultSport Clio 182 Trophy is a special little car. With only 550 being
                built, 500 for the UK and 50 for Switzerland, it is also quite a rare sight on
                our roads today. Over the last year or so the humble 182 Trophy has enjoyed a
                period of appreciation, both in terms of monetary value and in an increased
                interest from the wider performance car audience. Consequently, many of the
                cherished examples that have come up for sale have found their way into private
                collections and have been garaged as potential investments. It's highly unlikely
                that they'll reach the levels of appreciation enjoyed by its bonkers sibling, the
                RenaultSport Clio V6, but, years after release, it's great to see that the 182
                Trophy is still held in high regard by those who know their performance hot-hatches.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender02} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12">
              <div>
                However, it is arguably sad that many of these cars are no longer enjoyed as the
                RenaultSport Mesdames et Messieurs in Dieppe had intended. It is estimated that there
                are currently a little over three-hundred 182 Trophys on the road right now. That
                is quite a low number for a car that has received so many accolades over the years; a
                car that is often described as the epitome of hot-hatch fun. Thankfully, it's not all
                doom and gloom for this Capsicum Red B-road warrior! There's a group of 182 Trophy
                owners out there who love nothing more than getting out in their cars and spanking it
                along some of the finest tarmac that Wales has to offer. Welcome to the Trophy Welsh
                Weekender 2017.
              </div>
              <div className="mt-3">
                This was the third year that The Weekender had been held and, as tradition dictated, the
                starting point was Llangollen in north Wales. In true Welsh spirit, the late-September
                weather wasn't great as Trophy owners began to assemble in the car park under foreboding
                skies and the threat of rain. Previous years had been very kind to the group with unseasonably
                fine weather, but it was looking like that ship had sailed as owners mingled, routes were
                discussed and satnavs were handed out. Despite the relatively early start, the car park was
                already filling-up fast as tourists descended upon this popular Welsh town. It probably didn't
                help that 18 identical-looking red cars were occupying a significant number of parking
                bays, yet the owners and their cars were well received with random people keen to ask questions
                and grab photos. With all attendees accounted for, and a light mist falling, a procession of
                red Clios slowly snaked its way through town and on towards the roads that lay ahead.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender03} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12">
              <div>
                The first leg of the drive took the group up and over The Horseshoe Pass, a route that often presents
                spectacular views across the rolling hills and valleys of Denbighshire. Alas, it was not meant to be
                on this occasion. As the cars climbed up towards the summit, the low clouds and mist continued to
                descend. By the time the convoy had reached the highest point of the Pass the visibility was poor, severely
                hampering photo opportunities and, more concerning, reducing visibility down to a few tens of yards. 
                Thankfully, this was a relatively short-lived experience and the clouds started to dissipate whilst making
                the descent on the other side. That would be the last of the really bad weather and, from that point onwards, 
                it was simply about enjoying the drive, revelling in the camaraderie and experiencing the fantastic road-going
                abilities of the joyously capable 182 Trophy.
              </div>
              <div className="mt-3">
                Despite the sporty Clio generally being seen as a younger person's car it was surprising to see so many owners
                who were a few more years advanced than the demographic might suggest. Equally as surprising was the pride and
                enthusiasm that the owners had for their Trophys. Being very much petrol heads, the group collectively owned
                some seriously impressive vehicles in addition to their cherished Clios. Porsche GT3's, Cayman GT4's and other
                exotica shared garage space back home. The fact that these very same people are as happy to pick up the keys to
                their Trophy for the weekend, as opposed to immediately favouring their pricier stablemates, speaks volumes. On
                reflection, you'd be hard pushed to find a car that excels like the 182 Trophy on these roads. Even after all these
                years, and in a world of the super hot-hatch, the Trophy still punches well above its weight.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender04} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12">
              <div>
                The traffic certainly wasn't as kind this year when compared to previous Weekenders. Caravans, roadworks
                and oil-burning horseboxes were all seemingly intent on impeding progress. However, if there's something
                that Wales has in abundance - other than sheep - it's the miles and miles of challenging and scenic roads. 
                There were plenty of opportunities to challenge both car and driver as the planned route threaded its way
                around Snowdonia before heading south towards mid-Wales and Lake Vyrnwy. There was even an opportunity to
                enjoy a well-known triangular-shaped road network that often crops up in driving related discussions and
                press. Various points along the route were identified as suitable places to stop, sometimes for comfort and
                food breaks and sometimes to simply take in the naturally beautiful views that Wales has to offer. Lining
                up the cars never failed to attract attention either as motorists, and pedestrians, gazed on agog whilst
                the group further socialised and took photographs, collecting snapshots of the weekend for sharing and, no
                doubt, reminisicing over in years to come.
              </div>
              <div className="mt-3">
                The first day concluded in the historic market town of Aberystwyth and with the customary gathering
                together for an evening meal. With that came the opportunity to share a few laughs, to enjoy food and
                drinks amongst friends and to bid farewell to those who were only able to partake in the first day's
                activities. The following morning would see more Trophy owners joining the group; those who could only
                make the second day of the Weekender.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender05} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12">
              <div>
                Morning rolled around and a familiar red swarm had assembled at the starting point. Suitably fed and
                caffeinated, the convoy was soon rolling out of town. And what a great selection of roads to start the
                day! On a fine autumnal morning, and with relatively clear roads, the procession of Trophys were
                treated to a quite special drive. The mountain road out of Aberystwyth to Devil's Bridge, and then
                through Cwmystwyth to the top of Elan Valley, is such a great route when weather and traffic is in your
                favour. A blend of challenging and winding sections along varied and changeable road surfaces; tight
                and narrow in some places and open and sweeping in others. It was a delight to drive that particular
                morning. This was further enhanced by another incredibly enjoyable section of the route as the group
                headed south through Elan Valley, passing reservoirs and dams and making the most of the sweeping vistas
                for photo opportunities. That would prove a hard act to follow for the rest of the day. Despite not quite
                matching the highs of those earlier driving sections, the route still continued to deliver the thrills
                and variety that appeal to the driver within. The group threaded its way through the Brecon Beacons
                National Park and on towards an organised lunch stop with a very welcome Sunday roast. In true Sunday
                afternoon style, and in no doubt related to the rather large meal, the remainder of the day's route
                was a somewhat more sedate affair! A steady drive would eventually bring the Weekender to an end in
                Chepstow whereby farewells were exchanged and people started their journeys home.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender06} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-md-12">
              <div>
                Due to the nature of the Weekender it obviously involves a significant amount of driving
                and, consequently, time spent in the car. However, to focus on that alone would mean missing out on
                what is a very important and integral part of the event. And that is in the social interaction and
                friendliness between those who took part. It really made the weekend all the more special. Trophy
                owners turned up with their partners, their young families and with friends. Many came from far afield 
                (including one by ferry). Best friends took part together, as did father and son. There were many new
                faces alongside those that were already familiar. This accentuated the family-friendly nature of the
                event and highlighted quite clearly how strong and positive a car community can be. Ultimately it's as
                much about the people as it is about the drive and the cars. Plans for next year's event are already
                being discussed!
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-3 center-gentle-flex">
              <CaptionedImageContainer imageRef={imgWeekender07} caption="Trophy Welsh Weekender 2017" />
            </div>
          </div>
        </div>
      </section>
      <BackToTop />
    </div>
  );
};

export default WelshWeekender;
