import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NavBar from "./components/layout/NavBar";
import Footer from "./components/layout/Footer";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import Location from "./components/pages/Location";
import Search from "./components/pages/Search";
import Stats from "./components/pages/Stats";
import Contribute from "./components/pages/Contribute";
import Contact from "./components/pages/Contact";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import ChangeList from "./components/pages/ChangeList";
import MOTCheck from "./components/pages/MOTCheck";
import Submit from "./components/pages/Submit";
import TTAdmin from "./components/pages/TTAdmin";
import MeetTheTeam from "./components/pages/MeetTheTeam";
import IconicHotHatch from "./components/pages/IconicHotHatch";
import WelshWeekender from "./components/pages/WelshWeekender";

import TrophysState from "./context/trophys/TrophysState";
import AlertState from "./context/alert/AlertState";

import "./styling/App.css";

const App = () => {
  return (
    <TrophysState>
      <AlertState>
        <Router>
          <div className="App">
            <div className="app-content">
              <NavBar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/location" component={Location} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/search/num/:num" component={Search} />
                <Route exact path="/search/vrm/:vrm" component={Search} />
                <Route exact path="/search/vin/:vin" component={Search} />
                <Route exact path="/stats" component={Stats} />
                <Route exact path="/contribute" component={Contribute} />
                <Route exact path="/submit" component={Submit} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/motcheck" component={MOTCheck} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/changelist" component={ChangeList} />
                <Route exact path="/ttadmin" component={TTAdmin} />
                <Route exact path="/meettheteam" component={MeetTheTeam} />
                <Route exact path="/iconichothatch" component={IconicHotHatch} />
                <Route exact path="/welshweekender" component={WelshWeekender} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </AlertState>
    </TrophysState>
  );
};

export default App;
