import { Fragment } from 'react';

const Spinner = () => {
  return (
    <Fragment>
      <div className='my-3'>
        <div className='lds-ripple' style={{ margin: 'auto' }}>
          <div></div>
          <div></div>
        </div>
        <p style={{ textAlign: 'center' }}>One moment...</p>
      </div>
    </Fragment>
  );
};

export default Spinner;
