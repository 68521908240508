import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import MiniHeroSection from "../containers/MiniHeroSection";

const Contact = () => {
  const [svrReply, setSvrReply] = useState(null);
  const [textName, setTextName] = useState('');
  const [textEmail, setTextEmail] = useState('');
  const [textMsg, setTextMsg] = useState('');

  const onSubmitMsg = async evt => {
    evt.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.set('inName', textName);
    bodyFormData.set('inEmail', textEmail);
    bodyFormData.set('inMsg', textMsg);

    const url = `${process.env.REACT_APP_TTPHP_MESSENGER}`;
    const res = await axios.post(url, bodyFormData);

    if (res.status === 200) {
      setSvrReply(res.data);
    }
  };

  const onChangeName = evt => setTextName(evt.target.value);
  const onChangeEmail = evt => setTextEmail(evt.target.value);
  const onChangeMsg = evt => setTextMsg(evt.target.value);

  return (
    <div className='mb-5'>
      <MiniHeroSection titleText="Contact" sectionClassExtra="mb-3" iconName="fa-envelope" />
      
      <div className="container">
        {svrReply === null ? (
          <Fragment>
            <p>
              Please use this contact form if you need to get in touch with us.
            </p>
            <p>
              Alternatively, please use the form on the{' '}
              <Link to='/submit'>Submit page</Link> if you would like to provide
              information about a specific Trophy.
            </p>

            <form onSubmit={onSubmitMsg}>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label htmlFor='inputName' className='tt-label-submit-form'>
                    Your Name <span className='text-warning'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='inputName'
                    placeholder='Name...'
                    name='textName'
                    value={textName}
                    onChange={onChangeName}
                    required
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='inputEmail' className='tt-label-submit-form'>
                    Your E-mail <span className='text-warning'>*</span>
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    id='inputEmail'
                    placeholder='E-mail...'
                    name='textEmail'
                    value={textEmail}
                    onChange={onChangeEmail}
                    required
                  />
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-12'>
                  <label htmlFor='inputMessage' className='tt-label-submit-form'>
                    Your Message <span className='text-warning'>*</span>
                  </label>
                  <textarea
                    rows='5'
                    className='form-control'
                    id='inputMessage'
                    placeholder='Message...'
                    name='textMsg'
                    value={textMsg}
                    onChange={onChangeMsg}
                    required
                  />
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-12 mt-2'>
                  <button type='submit' className='btn btn-success'>
                    Send Message
                  </button>
                </div>
              </div>

              <div className=''>
                <span className='text-warning'>*</span> These fields are required.
              </div>
              <div className=''>
                <span className='text-warning'>*</span> Your e-mail address will
                not be used for any other reason than to reply to your message (if
                there is a need to do so).
              </div>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={
                'alert alert-' + (svrReply.type === 'ok' ? 'success' : 'warning')
              }
              role='alert'
            >
              <h3 className='alert-heading'>{textName}</h3>
              <p>{svrReply.message}</p>
              {svrReply.type === 'err' && (
                <button
                  className='btn btn-primary'
                  onClick={() => setSvrReply(null)}
                >
                  Try Again
                </button>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Contact;
