import { Bar, Line, Chart } from 'react-chartjs-2';

import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

const TTBarChartAllYears = ({listPricesByYear, chartType, chartDataDisplay}) => {
  if (listPricesByYear === null || chartType === null || chartDataDisplay === null) {
    return null;
  }

  var chartOptions = null;
  var chartData = null;

  const minColour = 'rgba(100, 150, 200, 1)';
  const avgColour = 'rgba(248, 148, 6, 1)';
  const maxColour = 'rgba(0, 150, 0, 1)';
  const borderWidth = 1;
  const axisLabelColour = 'rgba(160, 160, 160, 1)';
  const legendLabelColour = 'rgba(160, 160, 160, 1)';

  if (chartType === 'bar') {
    // Chart options setup
    chartOptions = {
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: borderWidth
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: legendLabelColour
          }
        },
        zoom: {
          limits: {
            y: { min: 'original', max: 'original' }
          },
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl'
          },
          zoom: {
            wheel: {
              enabled: true,
              modifierKey: 'ctrl'
            },
            pinch: {
              enabled: true
            },
            mode: 'x'
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: axisLabelColour
          }
        },
        y: {
          stacked: false,
          ticks: {
            color: axisLabelColour
          }
        }
      }
    };

    // Chart data setup
    chartData = {
      labels: [],
      datasets: []
    };

    // Extract yearly data and put into dataset
    if (chartDataDisplay === 'minPrice') {
      chartData.datasets.push({
        label: 'Minimum (£)',
        data: [],
        backgroundColor: [ avgColour ],
        borderColor: [ avgColour ],
        borderWidth: borderWidth
      });
    }
    else if (chartDataDisplay === 'avgPrice') {
      chartData.datasets.push({
        label: 'Average (£)',
        data: [],
        backgroundColor: [ avgColour ],
        borderColor: [ avgColour ],
        borderWidth: borderWidth
      });
    }
    else if (chartDataDisplay === 'maxPrice') {
      chartData.datasets.push({
        label: 'Maximum (£)',
        data: [],
        backgroundColor: [ avgColour ],
        borderColor: [ avgColour ],
        borderWidth: borderWidth
      });
    }
    else if (chartDataDisplay === 'combinedPrice') {
      chartData.datasets.push({
        label: 'Minimum (£)',
        data: [],
        backgroundColor: [ minColour ],
        borderColor: [ minColour ],
        borderWidth: borderWidth
      });
      chartData.datasets.push({
        label: 'Average (£)',
        data: [],
        backgroundColor: [ avgColour ],
        borderColor: [ avgColour ],
        borderWidth: borderWidth
      });
      chartData.datasets.push({
        label: 'Maximum (£)',
        data: [],
        backgroundColor: [ maxColour ],
        borderColor: [ maxColour ],
        borderWidth: borderWidth
      });
    }

    listPricesByYear.forEach((listItem) => {
      chartData.labels.push(listItem.year);

      switch (chartDataDisplay) {
        case 'minPrice':
          chartData.datasets[0].data.push(listItem.minPrice);
          break;

        default:
        case 'avgPrice':
          chartData.datasets[0].data.push(listItem.avgPrice);
          break;

        case 'maxPrice':
          chartData.datasets[0].data.push(listItem.maxPrice);
          break;

        case 'combinedPrice':
          chartData.datasets[0].data.push(listItem.minPrice);
          chartData.datasets[1].data.push(listItem.avgPrice);
          chartData.datasets[2].data.push(listItem.maxPrice);
          break;
      };
    });
  }
  else if (chartType === 'line')
  {
    // Chart options setup
    chartOptions = {
      interaction: {
        mode: 'index',
        intersect: false
      },      
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: axisLabelColour
          }
        },
        x: {
          ticks: {
            color: axisLabelColour
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: legendLabelColour
          }
        },
        zoom: {
          limits: {
            y: { min: 'original', max: 'original' }
          },
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl'
          },
          zoom: {
            wheel: {
              enabled: true,
              modifierKey: 'ctrl'
            },
            pinch: {
              enabled: true
            },
            mode: 'x'
          }
        }
      }    
    };

    // Chart data setup
    chartData = {
      labels: [],
      datasets: []
    };

    // Extract yearly data and put into dataset
    if (chartDataDisplay === 'minPrice') {
      chartData.datasets.push({
        label: 'Minimum (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ avgColour ],
        backgroundColor: [ avgColour ]
      });
    }
    else if (chartDataDisplay === 'avgPrice') {
      chartData.datasets.push({
        label: 'Average (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ avgColour ],
        backgroundColor: [ avgColour ]
      });
    }
    else if (chartDataDisplay === 'maxPrice') {
      chartData.datasets.push({
        label: 'Maximum (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ avgColour ],
        backgroundColor: [ avgColour ]
      });
    }
    else if (chartDataDisplay === 'combinedPrice') {
      chartData.datasets.push({
        label: 'Min (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ minColour ],
        backgroundColor: [ minColour ]
      });
      chartData.datasets.push({
        label: 'Avg (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ avgColour ],
        backgroundColor: [ avgColour ]
      });
      chartData.datasets.push({
        label: 'Max (£)',
        data: [],
        borderWidth: borderWidth,
        borderColor: [ maxColour ],
        backgroundColor: [ maxColour ]
      });
    }

    listPricesByYear.forEach((listItem) => {
      chartData.labels.push(listItem.year);

      if (chartDataDisplay === 'minPrice') {
        chartData.datasets[0].data.push(listItem.minPrice);
      }
      else if (chartDataDisplay === 'avgPrice') {
        chartData.datasets[0].data.push(listItem.avgPrice);
      }
      else if (chartDataDisplay === 'maxPrice') {
        chartData.datasets[0].data.push(listItem.maxPrice);
      }
      else if (chartDataDisplay === 'combinedPrice') {
        chartData.datasets[0].data.push(listItem.minPrice);
        chartData.datasets[1].data.push(listItem.avgPrice);
        chartData.datasets[2].data.push(listItem.maxPrice);
      }
    });
  }
  else {
    return null;
  }
  
  return (
    <>
      {chartType === 'bar' && (
        <div style={{height: "40vh", vmin: "40vh", vmax: "40vh"}}>
          <Bar data={chartData} options={chartOptions} />
        </div>
      )}

      {chartType === 'line' && (
        <div style={{height: "40vh", vmin: "40vh", vmax: "40vh"}}>
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </>
  );
}
 
export default TTBarChartAllYears;