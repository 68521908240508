import { Bar, Chart } from 'react-chartjs-2';
import Utility from '../util/Utility';

import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

const TTChartsMOTMileage = ({allMOTData}) => {
  if (allMOTData.motTests === null) {
    return null;
  }

  var chartOptions = null;
  var chartData = null;

  const barColour = 'rgba(91, 192, 222, 1)';
  const gridColour = 'rgba(60, 60, 60, 1)';
  const borderWidth = 1;
  const axisLabelColour = 'rgba(160, 160, 160, 1)';
  const legendLabelColour = 'rgba(160, 160, 160, 1)';

  // Chart options setup
  chartOptions = {
    indexAxis: 'x',
    elements: {
      bar: {
        borderWidth: borderWidth
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: legendLabelColour
        }
      },
      zoom: {
        limits: {
          y: { min: 'original', max: 'original' }
        },
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl'
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: 'ctrl'
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: axisLabelColour
        },
        grid: {
          color: gridColour
        }
      },
      y: {
        stacked: false,
        ticks: {
          color: axisLabelColour
        },
        grid: {
          color: gridColour
        }
      }
    }
  };

  // Chart data setup
  chartData = {
    labels: [],
    datasets: []
  };

  // Extract data and put into dataset
  chartData.datasets.push({
    label: 'Miles',
    data: [],
    backgroundColor: [ barColour ],
    borderColor: [ barColour ],
    borderWidth: borderWidth
  });

  let cleanedData = [];
  let currMileage = 0;
  let prevMileage = 0;

  allMOTData.motTests.slice().reverse().forEach((listItem, index) => {
    if (listItem.testResult === 'PASSED') {
      const year = new Date(listItem.completedDate).getFullYear();
      currMileage = parseInt(listItem.odometerValue, 10);
      const delta = currMileage - prevMileage;
      prevMileage = currMileage;

      cleanedData.push({ testDate: listItem.completedDate, year: year, mileage: currMileage, delta: delta });
    }
  });

  if (cleanedData.length === 0) {
    return null;
  }

  cleanedData[0]['delta'] = 0;

  const currYear = new Date().getFullYear();
  const regYear = new Date(allMOTData.firstUsedDate).getFullYear();
  // const startYear = cleanedData[0]['year'];
  // const startMileage = cleanedData[0]['mileage'];
  const endYear = cleanedData[cleanedData.length - 1]['year'];
  const endMileage = cleanedData[cleanedData.length - 1]['mileage'];
  const avgPerYear = Math.trunc(currMileage / (endYear - regYear));
  const carAgeYears = currYear - regYear;
  const highestMotPeriodDelta = Math.max.apply(Math, cleanedData.map((obj) => { return obj.delta; }))

  const ukAvgMileage = 7090;
  const ukAvgMileageOverLifetime = ukAvgMileage * carAgeYears;
  const ukAboveBelowAvgMileage = ukAvgMileageOverLifetime - endMileage;
  
  cleanedData.forEach((dataItem) => {
    chartData.labels.push(dataItem.year);
    chartData.datasets[0].data.push(dataItem.mileage);
  });

  return (
    <>
      <div className="card border-info mt-3">
        <div className="card-header bg-muted">
          <h5 className="mb-0">MILEAGE DATA FOR {allMOTData.registration}</h5>
        </div>
        <div className="card-body bg-dark">
          <div className="mb-3 font-weight-bold">MILEAGE CHART BY TEST YEAR</div>
          <div className="mb-5" style={{height: "40vh", vmin: "40vh", vmax: "40vh"}}>
            <Bar data={chartData} options={chartOptions} />
          </div>

          <div className="mb-3 font-weight-bold">MILEAGE DELTAS BETWEEN TESTS</div>
          <div className="row text-center bg-info text-dark">
            <div className="col font-weight-bold p-1">Date</div>
            <div className="col font-weight-bold p-1">Odometer</div>
            <div className="col font-weight-bold p-1">Delta (+/-)</div>
          </div>
          {cleanedData.map((dataItem, dataItemIdx) => {
            const bgFill = `row text-center ${(dataItemIdx % 2 === 0) ? 'bg-primary' : ''}`;
            return (
              <div key={dataItemIdx}>
                <div className={bgFill}>
                  <div className="col p-1">{dataItem.year}</div>
                  <div className="col p-1">{Utility.numWithCommas(dataItem.mileage)}</div>
                  <div className="col p-1">{Utility.numWithCommas(dataItem.delta)}</div>
                </div>
              </div>
            )
          })}
          <div className="row border-info mb-5" style={{borderTop: "1px solid"}}></div>

          <div className="mb-3 font-weight-bold">AVERAGE MILEAGE DATA</div>
          <div className="mb-2"><i className='fas fa-tachometer-alt fa-fw mr-2 text-warning' />Average mileage is <strong>{Utility.numWithCommas(avgPerYear)} {Utility.getPluralisedIfNeeded('mile', avgPerYear)}</strong> per year</div>
          <div className="mb-2"><i className='fas fa-history fa-fw mr-2 text-warning' />Last MOT mileage was <strong>{Utility.numWithCommas(endMileage)} {Utility.getPluralisedIfNeeded('mile', endMileage)}</strong></div>
          <div className="mb-2"><i className='fas fa-chart-bar fa-fw mr-2 text-warning' />Highest per MOT was <strong>{Utility.numWithCommas(highestMotPeriodDelta)} {Utility.getPluralisedIfNeeded('mile', highestMotPeriodDelta)}</strong></div>
          <div className="mb-3"><i className='fas fa-weight fa-fw mr-2 text-warning' />{Utility.numWithCommas(Math.abs(ukAboveBelowAvgMileage))} {Utility.getPluralisedIfNeeded('mile', Math.abs(ukAboveBelowAvgMileage))} <strong>{(ukAboveBelowAvgMileage < 0) ? 'above' : 'below'}</strong> average</div>
          <div className="mb-3">The calculated average mileage is <sup className="text-warning">*</sup><strong>{Utility.numWithCommas(ukAvgMileageOverLifetime)} miles</strong> for a {carAgeYears} year old car.</div>
          <div className="text-muted"><sup className="text-warning">*</sup><small>Calculations are made using the UK average car mileage which was {Utility.numWithCommas(ukAvgMileage)} miles per year (based on 2019 data).</small></div>
        </div>
      </div>
    </>
  );
}
 
export default TTChartsMOTMileage;
