import { useState } from 'react';
import axios from 'axios';

const TTAdmin = () => {
  const [adminPassword, setAdminPassword] = useState('');
  const [updateMileageVRM, setUpdateMileageVRM] = useState('');
  const [isPendingUpdateMileage, setIsPendingUpdateMileage] = useState(false);
  const [resultUpdateMileage, setResultUpdateMileage] = useState(null);

  const onChangeUpdateMileage = (vrm) => {
    vrm = vrm.replace(/\s+/g, '').toUpperCase();
    setUpdateMileageVRM(vrm);
  }

  const onClickUpdateMileage = async (e) => {
    //console.log('Attempt update for: ' + updateMileageVRM);
    e.preventDefault();
    
    setIsPendingUpdateMileage(true);
    setResultUpdateMileage('Processing. Please wait...');

    let bodyFormData = new FormData();
    bodyFormData.set('auth', adminPassword);
    bodyFormData.set('vrm', updateMileageVRM);

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_TTFUPDATEMILEAGE}`;

    await axios.post(url, bodyFormData).then(
      (response) => {
        if (response.data !== null) {
          const responseTTF = response.data;
          //console.log(responseTTF);

          setIsPendingUpdateMileage(false);
          setResultUpdateMileage(responseTTF.ttfmessage);
        }
        else {
          setIsPendingUpdateMileage(false);
          setResultUpdateMileage('ERROR: Unexpected server response.');
        }
      },
      (error) => {
        // Unable to perform update
        setIsPendingUpdateMileage(false);
        setResultUpdateMileage('ERROR: Unable to update record.');
      }
    );
  }

  return (
    <div className='my-5'>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <h3 className='text-white mb-3'>Administration #1</h3>
              <p>For TrophyTracker adminstration and data management duties.</p>

              <div className='card mb-3'>
                <div className='card-header'>
                  <h5 className='text-light mb-0'>Administration Pass-Phrase</h5>
                </div>
                <div className='card-body'>
                  <form>
                    <div className='mb-3'>
                      <input
                        className='form-control'
                        type='password'
                        value={adminPassword}
                        onChange={(e) => setAdminPassword(e.target.value)}
                      >
                      </input>
                    </div>
                  </form>
                </div>
              </div>            

              <div className='card'>
                <div className='card-header'>
                  <h5 className='text-light mb-0'>Update Mileage from MOT</h5>
                </div>
                <div className='card-body'>
                  <form>
                    <div className='mb-3'>
                      <label>Vehicle Registration Mark</label>
                      <input
                        className='form-control'
                        type='text'
                        value={updateMileageVRM}
                        onChange={(e) => onChangeUpdateMileage(e.target.value)}
                      >
                      </input>
                    </div>
                    
                    <button
                      type='button'
                      className='btn btn-secondary w-100'
                      onClick={onClickUpdateMileage}
                      disabled={isPendingUpdateMileage || (updateMileageVRM.length === 0) || (adminPassword.length === 0)}
                    >
                      Update
                    </button>
                    
                    {resultUpdateMileage !== null &&
                      <div className='mt-3'>{resultUpdateMileage}</div>
                    }
                  </form>
                </div>
              </div>            
            </div>

            <div className='col-md-6'>
              {/*
              <h3 className='text-info mb-3'>Administration #2</h3>
              <div className='mb-3'>
                To be used for TrophyTracker administration and data management duties.
              </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default TTAdmin;