import LazyLoad from "react-lazyload";

const CaptionedImageContainer = ({ imageRef, caption }) => {
  return (
    <div className="captioned-image-container">
      <LazyLoad offset={250} once>
        <img className="side-image-rounded" src={imageRef} alt="" title="" />
      </LazyLoad>
      <div className="captioned-image-container__caption">
        {caption}
      </div>
    </div>
  );
};

export default CaptionedImageContainer;
