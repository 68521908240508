import ReactHtmlParser from "react-html-parser";

const TTAdditionalNotesContainer = ({ trophyInfo }) => {
  return (
    <section className="tt-id-additional-notes">
      <h3 className="tt-heading text-warning">
        Additional Notes
      </h3>
      <div className="tt-id-additional-notes__container text-white mb-3">
        {trophyInfo.additionalNotes
          ? ReactHtmlParser(trophyInfo.additionalNotes)
          : "(No additional notes)"}
      </div>
    </section>
  );
};

export default TTAdditionalNotesContainer;
