import { useReducer } from 'react';
import axios from 'axios';

import TrophysContext from './trophysContext';
import TrophysReducer from './trophysReducer';

import {
  SEARCH_ALLDATA,
  CLEAR_ALLDATA,
  SEARCH_TROPHY,
  CLEAR_TROPHY,
  SEARCH_METRICS,
  CLEAR_METRICS,
  SET_LOADING,
  SET_SERVERERROR,
  REMOVE_SERVERERROR
} from '../types';

const TrophysState = props => {
  const initialState = {
    allData: [],
    trophy: null,
    metrics: null,
    serverError: null,
    loading: false
  };
  const [state, dispatch] = useReducer(TrophysReducer, initialState);

  // Search All Data
  const searchAllData = async () => {
    setLoading();

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_GETDATA}`;
    await axios.get(url).then(
      response => {
        dispatch({
          type: SEARCH_ALLDATA,
          payload: response.data
        });
      },
      () => {
        clearAllData();
        setServerError(
          'Sorry, unable to retrieve data from the server. Please try again later.',
          'danger'
        );
      }
    );
  };

  // Clear All Data
  const clearAllData = () => dispatch({ type: CLEAR_ALLDATA });

  // Search Trophy
  const searchTrophy = async (reqOp, searchTerm) => {
    clearTrophy();
    setLoading();

    let bodyFormData = new FormData();
    bodyFormData.set('reqOp', reqOp);
    bodyFormData.set('reqSearchTerm', searchTerm);

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_GETTROPHY}`;
    await axios.post(url, bodyFormData).then(
      response => {
        dispatch({
          type: SEARCH_TROPHY,
          payload: response.data
        });
      },
      () => {
        clearTrophy();
        setServerError(
          'Sorry, unable to retrieve data from the server. Please try again later.',
          'danger'
        );
      }
    );
  };

  // Clear Trophy
  const clearTrophy = () => dispatch({ type: CLEAR_TROPHY });

  // Search Metrics
  const searchMetrics = async () => {
    setLoading();

    const url = `${process.env.REACT_APP_TTHOST}${process.env.REACT_APP_TTPHP_GETMETRICS}`;
    await axios.get(url).then(
      response => {
        dispatch({
          type: SEARCH_METRICS,
          payload: response.data
        });
      },
      () => {
        clearMetrics();
        setServerError(
          'Sorry, unable to retrieve metrics from the server. Please try again later.',
          'danger'
        );
      }
    );
  };

  // Clear Metrics
  const clearMetrics = () => dispatch({ type: CLEAR_METRICS });

  // Set Server Error
  const setServerError = (message, type) => {
    dispatch({ type: SET_SERVERERROR, payload: { msg: message, type: type } });
    setTimeout(() => dispatch({ type: REMOVE_SERVERERROR }), 5000);
  };

  // Set Loading
  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };

  return (
    <TrophysContext.Provider
      value={{
        allData: state.allData,
        trophy: state.trophy,
        metrics: state.metrics,
        serverError: state.serverError,
        loading: state.loading,
        searchAllData,
        clearAllData,
        searchTrophy,
        clearTrophy,
        searchMetrics,
        clearMetrics,
        setServerError,
        setLoading
      }}
    >
      {props.children}
    </TrophysContext.Provider>
  );
};

export default TrophysState;
