import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import ServerError from "../layout/ServerError";
import ButtonLink from "../trophys/ButtonLink";
import SearchLocation from "../trophys/SearchLocation";
import Spinner from "../layout/Spinner";
import BackToTop from "../layout/BackToTop";
import Utility from "../util/Utility";
import MiniHeroSection from "../containers/MiniHeroSection";

import TrophysContext from "../../context/trophys/trophysContext";

import { REQOP_BY_NUM } from "../../context/types";

const Location = () => {
  const history = useHistory();
  const trophysContext = useContext(TrophysContext);

  const onSelectTrophy = (trophyNum) => {
    trophysContext.searchTrophy(REQOP_BY_NUM, trophyNum);
    history.push("/search");
  };

  useEffect(() => {
    !trophysContext.allData.locAll &&
      (async () => {
        await trophysContext.searchAllData();
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-5">
      <MiniHeroSection titleText="Location" sectionClassExtra="mb-3" iconName="fa-map-marker-alt" />

      <div className="container">
        <p>
          The information presented here shows where Trophys <em>may</em> be
          located (denoted by their unique number).
        </p>

        <ServerError />
        <SearchLocation />

        {trophysContext.loading && <Spinner />}

        <div className="mb-5">
          {trophysContext.allData.locAll &&
            trophysContext.allData.locAll.length > 0 && (
              <>
                {trophysContext.allData.locAll
                  .filter((loc) => loc.trophys.length > 0)
                  .map((loc, locIdx) => {
                    return (
                      <div key={locIdx}>
                        {loc.uid === 0 && (
                          <div className="mt-4">
                            <i className="mr-2 fas fa-info-circle" />
                            Vehicles that are highlighted in{" "}
                            <span className="text-danger">red</span> have been{" "}
                            <span className="text-danger">scrapped</span>.
                          </div>
                        )}

                        <div className="card border-success mt-4 text-white">
                          <div className="card-header">
                            <strong>
                              {loc.name} (
                              {Utility.getPluralisedWithValueIfNeeded(
                                "car",
                                loc.trophys.length
                              )}
                              )
                            </strong>
                          </div>
                          <div className="card-body">
                            <div>{loc.description}</div>

                            <div className="text-white mt-3 d-flex justify-content-start flex-wrap">
                              {loc.trophys.map((trophy, trophyIdx) => {
                                return (
                                  <ButtonLink
                                    key={trophyIdx}
                                    content={trophy.number
                                      .toString()
                                      .padStart(3, "0")}
                                    classes={
                                      trophy.isScrapped
                                        ? "btn btn-danger"
                                        : "btn btn-primary"
                                    }
                                    onClickLink={() =>
                                      onSelectTrophy(trophy.number)
                                    }
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        {loc.uid === 0 && (
                          <div className="mt-4">
                            <i className="mr-2 fas fa-info-circle" />
                            (Countries are listed in alphabetical order)
                          </div>
                        )}
                      </div>
                    );
                  })}
              </>
            )}
        </div>
        <BackToTop />
      </div>
    </div>
  );
};

export default Location;
