import BiogCard from "../trophys/BiogCard";

import MiniHeroSection from "../containers/MiniHeroSection";

const MeetTheTeam = () => {
  return (
    <div className="mb-5">
      <MiniHeroSection
        titleText="Meet The Team"
        sectionClassExtra="mb-3"
        iconName="fa-users"
      />

      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-4">
            <BiogCard
              name="Andy Eder"
              imgUrl="https://avatars.githubusercontent.com/u/14290788"
            >
              Hi, I'm Andy and I have owned a Renaultsport Clio 182 Trophy since
              2007. As part of a welcoming and widening Trophy community I
              thought it might be a fun idea to reach-out in an attempt to pull
              together some kind of Trophy register. Hence, this small website
              project was born - a project that I wanted to share with the
              community. I am delighted to welcome my good friend and
              professional colleague, Tracy, to the team.
            </BiogCard>
          </div>
          <div className="col-md-6">
            <BiogCard
              name="Tracy Gilmore"
              imgUrl="https://avatars.githubusercontent.com/u/4346753"
            >
              Hello, I'm Tracy Gilmore (and yes it is an odd name for a guy.)
              Over the last few years I have had the pleasure of working
              along-side Andy on another project and am very pleased to be
              joining him in developing and maintaining TrophyTracker. You might
              have already noticed some changes, including this Meet the Team
              page, and over the coming months I hope to bring new features and
              facilities to benefit the community.
            </BiogCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
